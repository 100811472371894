import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  Card,
  Tabs,
  Tab,
  Typography,
  Box,
  Grid,
  TextField,
  FormLabel,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  Button,
} from "@material-ui/core";

import convertToBase64 from "utils/convertToBase64";
import validateFileSize from "utils/validate-file-size";
import rightFormStyles from "../right-form.module.scss";
import {
  validString,
  validShortNumber,
  validPostalCode,
} from "../../../../utils/validations/forms/update-form";
import { AttachedFile, FileDropzone, Loader } from "../../..";
import truncateString from "../../../../utils/truncate-string";
import useUserCatalogs from "../../../../utils/catalogs/useUserCatalogs";
import {
  updateUserDetailsSelf,
  updateUserDetails,
  loginSuccess,
  reset,
} from "../../../../redux/actions";

const TabForms = ({ userData = {} }) => {
  const { t, i18n } = useTranslation("profile");
  const currentLanguage = i18n.language;
  const dispatch = useDispatch();

  const { loggedUser } = useSelector((store) => store.loggedUser);
  const {
    loading,
    success,
    updatedUserSelfDetails,
    updatedUserDetails,
  } = useSelector((store) => store.userDetails);

  const [tabValue, setTabValue] = useState(0);
  const [tabsEditMode, setTabsEditMode] = useState(false);
  const [loadingError, setLoadingError] = useState(false);

  const {
    roleId,
    companySizes,
    companyTypes,
    attendedMarkets,
    certificationOptions,
    interactiveMedias,
    sectors,
    informationTechnologies,
    telecommunications,
    industries4,
    belongingClusters,
  } = useUserCatalogs();

  // Validate if user is provided by admin on public profile or logged user is trying to update its own profile
  const isAdminUpdatingPublicUser = Object.keys(userData).length;

  let userDataToUpdate = {
    country: "",
    state: "",
    street: "",
    exteriorNumber: "",
    interiorNumber: "",
    neighborhood: "",
    city: "",
    postalCode: "",

    generalServiceDescription: "",
    energeticClusterMember: "",


    // companyOriginCountry: "",
    // businessSeniorityYears: "",
    // companySize: "",
    threeMostImportantClients: "",
    branchesLocation: "",
    otherBelongingClusters: "",
    otherCertifications: "",

    // positiveOpinionSatLetter: false,
    // paymentTerms: "",
    // lastYearAnnualSale: "",
    // otherFinancialIntegrityCertifications: "",

    assemblyAreas: "",
    productionLeadTime: "",
    manufacturingCapabilities: "",
    extraTimePercentage: "",
    ppapExperience: false,
    ppmClientTotals: "",
    plantsQuantity: "",
    turnsQuantity: "",
    administrativeEmployeesQuantity: "",
    operativeEmployeesQuantity: "",
    incoterms: "",

    companyInformation: {
      companyType: "",
      attendedMarket: "",
      certifications: "",
      belongingCluster: "",
    },
    sectorInformation: {
      sector: "",
      informationTechnology: "",
      telecommunication: "",
      interactiveMedia: "",
      industry4: "",
    },
    companyPresentation: {
      rfcTaxIdentificationCard: {
        name: "",
        url: "",
      },
    },
    rfcTaxIdentificationCard: {
      fileName: "",
    },
  };

  if (!isAdminUpdatingPublicUser) {
    userDataToUpdate = { ...userDataToUpdate, ...loggedUser };
  } else {
    userDataToUpdate = { ...userDataToUpdate, ...userData };
  }

  const {
    taxResidence: {
      country: userCountry,
      state: userState,
      street: userStreet,
      exteriorNumber: userExteriorNumber,
      interiorNumber: userInteriorNumber,
      neighborhood: userNeighborhood,
      city: userCity,
      postalCode: userPostalCode,
    },
    companyInformation: {
      // companyOriginCountry: userCompanyOriginCountry,
      // businessSeniorityYears: userBusinessSeniorityYears,
      // companySize: userCompanySize,
      otherMainAttendedSectors: userOtherMainAttendedSectors,
      threeMostImportantClients: userThreeMostImportantClients,
      branchesLocation: userBranchesLocation,
      otherBelongingClusters: userOtherBelongingClusters,
      otherCertifications: userOtherCertifications,
      belongingCluster: userBelongingCluster,
      energeticClusterMember: userEnergeticClusterMember
    },
    sectorInformation: {
      sector: userSector,
      informationTechnology: userInformationTechnology,
      telecommunication: userTelecommunication,
      interactiveMedia: userInteractiveMedia,
      industry4: userndustry4,
      generalServiceDescription: userGeneralServiceDescription,
    },
    financialIntegrity: {
      // positiveOpinionSatLetter: userPositiveOpinionSatLetter,
      // paymentTerms: userPaymentTerms,
      // lastYearAnnualSale: userLastYearAnnualSale,
      // otherFinancialIntegrityCertifications: userOtherFinancialIntegrityCertifications,
    },
  } = userDataToUpdate;
  const {
    role: { _id },
  } = loggedUser;

  const displayEditActions = isAdminUpdatingPublicUser
    ? _id === roleId.GOD || _id === roleId.ADMIN
      ? true
      : false
    : true;

  // Populate user to update with values from db
  const [userToUpdate, setUserToUpdate] = useState({
    ...userDataToUpdate,
    country: userCountry,
    state: userState,
    street: userStreet,
    exteriorNumber: userExteriorNumber,
    interiorNumber: userInteriorNumber,
    neighborhood: userNeighborhood,
    city: userCity,
    postalCode: userPostalCode,

    generalServiceDescription: userGeneralServiceDescription,
    energeticClusterMember: userEnergeticClusterMember,

    // companyOriginCountry: userCompanyOriginCountry,
    // businessSeniorityYears: userBusinessSeniorityYears,
    // companySize: userCompanySize,
    otherMainAttendedSectors: userOtherMainAttendedSectors,
    threeMostImportantClients: userThreeMostImportantClients,
    branchesLocation: userBranchesLocation,
    otherBelongingClusters: userOtherBelongingClusters,
    otherCertifications: userOtherCertifications,

    // positiveOpinionSatLetter: userPositiveOpinionSatLetter,
    // paymentTerms: userPaymentTerms,
    // lastYearAnnualSale: userLastYearAnnualSale,
    // otherFinancialIntegrityCertifications: userOtherFinancialIntegrityCertifications,

    generalServices: "",
    brochure: { base64: "", fileName: "" },
    companyLogo: { base64: "", fileName: "" },
    constitutiveAct: { base64: "", fileName: "" },
    rfcTaxIdentificationCard: { base64: "", fileName: "" },
  });

  const {
    country,
    state,
    street,
    exteriorNumber,
    interiorNumber,
    neighborhood,
    city,
    postalCode,

    // companyOriginCountry,
    // businessSeniorityYears,
    // companySize,
    threeMostImportantClients,
    branchesLocation,
    otherBelongingClusters,
    otherCertifications,

    // positiveOpinionSatLetter,
    //paymentTerms,
    // lastYearAnnualSale,
    // otherFinancialIntegrityCertifications,

    assemblyAreas,
    productionLeadTime,
    manufacturingCapabilities,
    extraTimePercentage,
    ppapExperience,
    ppmClientTotals,
    plantsQuantity,
    turnsQuantity,
    administrativeEmployeesQuantity,
    operativeEmployeesQuantity,
    incoterms,

    companyInformation: {
      companyType,
      attendedMarket,
      certifications,
      belongingCluster,
    },
    generalServiceDescription,
    energeticClusterMember,
    sectorInformation: {
      sector,
      informationTechnology,
      telecommunication,
      interactiveMedia,
      industry4,
      
    },
    companyPresentation: {
      rfcTaxIdentificationCard: {
        name: rfcTaxIdentificationCardName,
        url: rfcTaxIdentificationCardUrl,
      },
    },
    rfcTaxIdentificationCard: {
      fileName: rfcTaxIdentificationCardUploadedFileName,
    },
    generalServices,
  } = userToUpdate;

  const defaultUser = { ...userToUpdate };

  const tabsContent = [
    {
      label: {
        es: "Datos Generales de la Empresa",
        en: "General Information About the Company",
      },
      sections: [
        {
          es: "Otros Datos de la Empresa",
          en: "Other Company Data",
        },
      ],
    },
    {
      label: {
        es: "Descripción general de servicios",
        en: "General description of services",
      },
    },
    {
      label: {
        es: "Ubicación",
        en: "Location",
      },
    },
    {
      label: {
        es: "Socios o no socios",
        en: "Member or non member",
      },
    },
  ];

  // -- Effects --
  // Disable save(update) button in case a value of the form is not valid
  useEffect(() => {
    if (
      validString(state, 2, 25) ||
      validString(country) ||
      validString(street) ||
      validString(neighborhood) ||
      validString(city) ||
      validPostalCode(postalCode) ||
      // validString(companyOriginCountry) ||
      // validString(otherFinancialIntegrityCertifications, 5, 150) ||
      // validString(threeMostImportantClients, 3, 150) ||
      validString(branchesLocation, 3, 150) ||
      validString(otherBelongingClusters, 3, 50) ||
      validString(otherCertifications, 3, 50) ||
      validString(assemblyAreas, 1, 200) ||
      validString(productionLeadTime, 1, 200) ||
      validString(manufacturingCapabilities, 1, 200) ||
      validString(extraTimePercentage, 1, 50) ||
      validString(ppmClientTotals, 1, 200) ||
      validString(plantsQuantity, 1, 50) ||
      validString(turnsQuantity, 1, 50) ||
      validString(administrativeEmployeesQuantity, 1, 50) ||
      validString(operativeEmployeesQuantity, 1, 50) ||
      validString(incoterms, 2, 200)
    ) {
      setLoadingError(true);
    } else {
      setLoadingError(false);
    }
  }, [
    state,
    country,
    street,
    neighborhood,
    city,
    postalCode,
    // companyOriginCountry,
    // otherFinancialIntegrityCertifications,
    threeMostImportantClients,
    branchesLocation,
    otherBelongingClusters,
    otherCertifications,

    assemblyAreas,
    productionLeadTime,
    manufacturingCapabilities,
    extraTimePercentage,
    ppmClientTotals,
    plantsQuantity,
    turnsQuantity,
    administrativeEmployeesQuantity,
    operativeEmployeesQuantity,
    incoterms,
  ]);
  // Mark checked checkboxes for multiple selection fields
  useEffect(() => {
    markCheckedOptions(companyType, companyTypes.array, companyTypes.setter);
    markCheckedOptions(
      belongingCluster,
      belongingClusters.array,
      belongingClusters.setter
    );
    markCheckedOptions(sector, sectors.array, sectors.setter);
    let searchMatches = 0;
    for (let j = 0; j < sector.length; j++) {
      for (let i = 0; i < sectors.array.length; i++) {
        if (sector[j] === sectors.array[i].value) {
          searchMatches++;
        }
      }
      if (searchMatches !== 0) {
      } else {
        userToUpdate.generalServices = sector[j];
      }
      searchMatches = 0;
    }

    markCheckedOptions(
      informationTechnology,
      informationTechnologies.array,
      informationTechnologies.setter
    );
    markCheckedOptions(
      telecommunication,
      telecommunications.array,
      telecommunications.setter
    );
    markCheckedOptions(
      interactiveMedia,
      interactiveMedias.array,
      interactiveMedias.setter
    );
    markCheckedOptions(industry4, industries4.array, industries4.setter);
    markCheckedOptions(
      attendedMarket,
      attendedMarkets.array,
      attendedMarkets.setter
    );
    markCheckedOptions(
      certifications,
      certificationOptions.array,
      certificationOptions.setter
    );
  }, []);
  // Disable edit mode when user details were successfuly updated
  useEffect(() => {
    if (success) {
      setTabsEditMode(false);
      // Update user without refresh with updated response
      if (!isAdminUpdatingPublicUser) {
        dispatch(loginSuccess(updatedUserSelfDetails));
        setUserToUpdate({ ...userToUpdate, ...updatedUserSelfDetails });
      } else {
        setUserToUpdate({ ...userToUpdate, ...updatedUserDetails });
      }
      Swal.fire({
        icon: "success",
        title: "Datos adicionales actualizados exitosamente",
        timer: 3000,
      });
      dispatch(reset());
    }
  }, [success]);

  // -- Functions --
  const handleInputChange = (inputEvent) => {
    setUserToUpdate({
      ...userToUpdate,
      [inputEvent.target.name]: inputEvent.target.value,
    });
  };
  const handleSwitchRadio = (inputEvent) => {
    console.log('handleSwitchRadio: ', inputEvent.target);
  }
  const handleRadioChange = (e, fieldName) => {
    if (e.target.value === "true") {
      setUserToUpdate({ ...userToUpdate, [fieldName]: true });
    } else {
      setUserToUpdate({ ...userToUpdate, [fieldName]: false });
    }
  };
  const onDropFile = async (files, fieldName, maxFileSize) => {
    const selectedFile = files[0];

    const isValidSizeFile = validateFileSize(selectedFile.size, maxFileSize);

    // Validate max file size, clean dropped file in case it's invalid
    if (isValidSizeFile) {
      let base64File = undefined;
      try {
        base64File = await convertToBase64(selectedFile);
        setUserToUpdate({
          ...userToUpdate,
          [fieldName]: { base64: base64File, fileName: selectedFile.name },
        });
      } catch (error) {
        throw new Error(error);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Archivo muy pesado",
        text: `El tamaño máximo permitido del archivo es de ${maxFileSize}MB, inténtalo de nuevo.`,
      });

      setUserToUpdate({
        ...userToUpdate,
        [fieldName]: { base64: "", fileName: "" },
      });

      return;
    }
  };
  const onCancelUpload = () => {
    // console.log("ON CANCEL UPLOAD");
  };
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleMultipleSelectionChange = (value, options, setter) => {
    options.forEach((option) => {
      if (option.value === value) option.checked = !option.checked;
    });

    setter([...options]);
  };
  const markCheckedOptions = (savedOptions, options, setter) => {
    savedOptions.forEach((savedOption) => {
      options.forEach((option) => {
        if (savedOption === option.value) {
          option.checked = true;
        }
      });
    });
    setter([...options]);
  };
  const getMultipleSelectionArray = (array) => {
    const newArray = [];
    array.forEach((object) => {
      if (object.checked) newArray.push(object.value);
    });

    return newArray;
  };
  const prepareUserUpdate = (user) => {
    const newCompanyType = getMultipleSelectionArray(companyTypes.array);
    const newAttendedMarket = getMultipleSelectionArray(attendedMarkets.array);
    const newSector = getMultipleSelectionArray(sectors.array);
    const newInformationTechnology = getMultipleSelectionArray(
      informationTechnologies.array
    );
    const newTelecommunication = getMultipleSelectionArray(
      telecommunications.array
    );
    const newInteractiveMedia = getMultipleSelectionArray(
      interactiveMedias.array
    );
    const newIndustry4 = getMultipleSelectionArray(industries4.array);
    const newBelongingCluster = getMultipleSelectionArray(
      belongingClusters.array
    );
    const newCertifications = getMultipleSelectionArray(
      certificationOptions.array
    );

    if (userToUpdate.generalServices && userToUpdate.generalServices !== "") {
      newSector.push(userToUpdate.generalServices);
    }
    const newUser = {
      ...user,
      companyType: newCompanyType,
      attendedMarket: newAttendedMarket,
      certifications: newCertifications,
      belongingCluster: newBelongingCluster,
      sector: newSector,
      informationTechnology: newInformationTechnology,
      telecommunication: newTelecommunication,
      interactiveMedia: newInteractiveMedia,
      industry4: newIndustry4,
    };

    if (!isAdminUpdatingPublicUser) {
      dispatch(updateUserDetailsSelf(newUser));
    } else {
      dispatch(updateUserDetails(newUser._id, newUser));
    }
  };
  const cancelEditUserDetails = () => {
    setUserToUpdate({ ...userToUpdate, ...defaultUser });
    setTabsEditMode(false);
  };
  const LoadingIndicator = () => {
    if (loading) {
      return (
        <div className="mt-4">
          <Loader />
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <div className="d-flex justify-content-center">
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="simple tabs example"
          className="mt-4"
          variant="scrollable"
          indicatorColor="primary"
        >
          {tabsContent.map(({ label }, index) => (
            <Tab key={index} label={label[currentLanguage]} />
          ))}
        </Tabs>
      </div>

      <Card className="p-3 mt-4 w-100">
        <div className="d-flex flex-column">
          <div>
            <TabPanel value={tabValue} index={0}>
              <h5 className="mb-4">
                {tabsContent[tabValue].label[currentLanguage]}
              </h5>

              <Grid container spacing={4}>
                <Grid item className={rightFormStyles["form-item"]}>
                  <TextField
                    type="text"
                    value={country}
                    id="country"
                    name="country"
                    label={t("tabForms.tab1.country")}
                    placeholder={t("tabForms.tab1.countryPlaceholder")}
                    autoComplete="off"
                    fullWidth
                    disabled={!tabsEditMode || loading}
                    onChange={handleInputChange}
                    error={validString(country)}
                    helperText={
                      country.length && validString(country)
                        ? t("tabForms.tab1.countryHelperText")
                        : null
                    }
                  />
                </Grid>
                <Grid item className={rightFormStyles["form-item"]}>
                  <TextField
                    type="text"
                    value={state}
                    id="state"
                    name="state"
                    label={t("tabForms.tab1.state")}
                    placeholder={t("tabForms.tab1.statePlaceholder")}
                    autoComplete="off"
                    fullWidth
                    disabled={!tabsEditMode || loading}
                    onChange={handleInputChange}
                    error={validString(state, 2, 25)}
                    helperText={
                      state.length && validString(state, 2, 25)
                        ? t("tabForms.tab1.stateHelperText")
                        : null
                    }
                  />
                </Grid>
                <Grid item className={rightFormStyles["form-item"]}>
                  <TextField
                    type="text"
                    value={street}
                    id="street"
                    name="street"
                    label={t("tabForms.tab1.street")}
                    placeholder={t("tabForms.tab1.streetPlaceholder")}
                    autoComplete="off"
                    fullWidth
                    disabled={!tabsEditMode || loading}
                    onChange={handleInputChange}
                    error={validString(street)}
                    helperText={
                      street.length && validString(street)
                        ? t("tabForms.tab1.streetHelperText")
                        : null
                    }
                  />
                </Grid>
                <Grid item className={rightFormStyles["form-item"]}>
                  <TextField
                    type="number"
                    value={exteriorNumber}
                    id="exteriorNumber"
                    name="exteriorNumber"
                    label={t("tabForms.tab1.exteriorNumber")}
                    placeholder={t("tabForms.tab1.exteriorNumberPlaceholder")}
                    autoComplete="off"
                    fullWidth
                    disabled={!tabsEditMode || loading}
                    onChange={handleInputChange}
                    error={validShortNumber(exteriorNumber)}
                    helperText={
                      exteriorNumber.length && validShortNumber(exteriorNumber)
                        ? t("tabForms.tab1.exteriorNumberHelperText")
                        : null
                    }
                  />
                </Grid>
                <Grid item className={rightFormStyles["form-item"]}>
                  <TextField
                    type="number"
                    value={interiorNumber}
                    id="interiorNumber"
                    name="interiorNumber"
                    label={t("tabForms.tab1.interiorNumber")}
                    placeholder={t("tabForms.tab1.interiorNumberPlaceholder")}
                    autoComplete="off"
                    fullWidth
                    disabled={!tabsEditMode || loading}
                    onChange={handleInputChange}
                    error={validShortNumber(interiorNumber)}
                    helperText={
                      interiorNumber.length && validShortNumber(interiorNumber)
                        ? t("tabForms.tab1.interiorNumberHelperText")
                        : null
                    }
                  />
                </Grid>
                <Grid item className={rightFormStyles["form-item"]}>
                  <TextField
                    type="text"
                    value={neighborhood}
                    id="neighborhood"
                    name="neighborhood"
                    label={t("tabForms.tab1.neighborhood")}
                    placeholder={t("tabForms.tab1.neighborhoodPlaceholder")}
                    autoComplete="off"
                    fullWidth
                    disabled={!tabsEditMode || loading}
                    onChange={handleInputChange}
                    error={validString(neighborhood)}
                    helperText={
                      neighborhood.length && validString(neighborhood)
                        ? t("tabForms.tab1.neighborhoodHelperText")
                        : null
                    }
                  />
                </Grid>
                <Grid item className={rightFormStyles["form-item"]}>
                  <TextField
                    type="text"
                    value={city}
                    id="city"
                    name="city"
                    label={t("tabForms.tab1.city")}
                    placeholder={t("tabForms.tab1.cityPlaceholder")}
                    autoComplete="off"
                    fullWidth
                    disabled={!tabsEditMode || loading}
                    onChange={handleInputChange}
                    error={validString(city)}
                    helperText={
                      city.length && validString(city)
                        ? t("tabForms.tab1.cityHelperText")
                        : null
                    }
                  />
                </Grid>
                <Grid item className={rightFormStyles["form-item"]}>
                  <TextField
                    type="text"
                    value={postalCode}
                    id="postalCode"
                    name="postalCode"
                    label={t("tabForms.tab1.postalCode")}
                    placeholder={t("tabForms.tab1.postalCodePlaceholder")}
                    autoComplete="off"
                    fullWidth
                    disabled={!tabsEditMode || loading}
                    onChange={handleInputChange}
                    error={validPostalCode(postalCode)}
                    helperText={
                      postalCode.length && validPostalCode(postalCode)
                        ? t("tabForms.tab1.postalCodeHelperText")
                        : null
                    }
                  />
                </Grid>
                {/* <Grid item className={rightFormStyles["form-item"]}>
                  <TextField
                    type="text"
                    value={companyOriginCountry}
                    id="companyOriginCountry"
                    name="companyOriginCountry"
                    label={t("tabForms.tab1.companyOriginCountry")}
                    placeholder={t(
                      "tabForms.tab1.companyOriginCountryPlaceholder"
                    )}
                    autoComplete="off"
                    fullWidth
                    disabled={!tabsEditMode || loading}
                    onChange={handleInputChange}
                    error={validString(companyOriginCountry)}
                    helperText={
                      companyOriginCountry.length &&
                      validString(companyOriginCountry)
                        ? t("tabForms.tab1.companyOriginCountryHelperText")
                        : null
                    }
                  />
                </Grid> */}
                {/* <Grid item className={rightFormStyles["form-item"]}>
                  <TextField
                    type="number"
                    value={businessSeniorityYears}
                    id="businessSeniorityYears"
                    name="businessSeniorityYears"
                    label={t("tabForms.tab1.businessSeniorityYears")}
                    placeholder={t(
                      "tabForms.tab1.businessSeniorityYearsPlaceholder"
                    )}
                    autoComplete="off"
                    fullWidth
                    disabled={!tabsEditMode || loading}
                    onChange={handleInputChange}
                    error={validShortNumber(businessSeniorityYears)}
                    helperText={
                      businessSeniorityYears.length &&
                      validShortNumber(businessSeniorityYears)
                        ? t("tabForms.tab1.businessSeniorityYearsHelperText")
                        : null
                    }
                  />
                </Grid> */}
                {/* <Grid item className={rightFormStyles["form-item"]}>
                  <TextField
                    id="companySize"
                    name="companySize"
                    value={companySize || ""}
                    label={t("tabForms.tab1.companySize")}
                    fullWidth
                    disabled={!tabsEditMode || loading}
                    select
                    onChange={handleInputChange}
                  >
                    {companySizes.array.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label[currentLanguage]}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid> */}
              </Grid>

              <Grid container spacing={4}>
                <Grid item className="w-100">
                  <p className={rightFormStyles["form-label-subtitle"]}>
                    {t("tabForms.tab1.companyType")}
                  </p>
                  {companyTypes.array.map(
                    ({ value, label, checked }, index) => {
                      return (
                        <FormControlLabel
                          className={rightFormStyles["form-item-checkbox"]}
                          key={index}
                          control={
                            <Checkbox
                              inputProps={{
                                "data-testid": "company-type-checkbox",
                              }}
                              color="primary"
                              value={value}
                              checked={checked}
                              onChange={() =>
                                handleMultipleSelectionChange(
                                  value,
                                  companyTypes.array,
                                  companyTypes.setter
                                )
                              }
                            />
                          }
                          label={label[currentLanguage]}
                          disabled={!tabsEditMode || loading}
                        />
                      );
                    }
                  )}
                </Grid>
              </Grid>

              <h5 className="my-4 pb-2">
                {tabsContent[tabValue].sections &&
                  tabsContent[tabValue].sections[0][currentLanguage]}
              </h5>

              {/* <Grid container spacing={4}>
                <Grid item className={`${rightFormStyles["form-item"]} pb-0`}>
                  <p className={rightFormStyles["form-label"]}>
                    {t("tabForms.tab1.positiveOpinionSatLetter")}
                  </p>
                  <RadioGroup
                    aria-label="positiveOpinionSatLetter"
                    name="positiveOpinionSatLetter"
                    value={positiveOpinionSatLetter}
                    onChange={(e) =>
                      handleRadioChange(e, "positiveOpinionSatLetter")
                    }
                  >
                    <div className="d-flex">
                      <FormControlLabel
                        data-testid="positive-opinion-sat-letter-radio"
                        value={true}
                        control={<Radio color="primary" />}
                        label={t(
                          "tabForms.tab1.positiveOpinionSatLetterYESoption"
                        )}
                        disabled={!tabsEditMode || loading}
                      />
                      <FormControlLabel
                        data-testid="positive-opinion-sat-letter-radio"
                        value={false}
                        control={<Radio color="primary" />}
                        label="NO"
                        disabled={!tabsEditMode || loading}
                      />
                    </div>
                  </RadioGroup>
                </Grid>
                <Grid item className={rightFormStyles["form-item"]}>
                  <TextField
                    type="number"
                    value={paymentTerms}
                    id="paymentTerms"
                    name="paymentTerms"
                    label={t("tabForms.tab1.paymentTerms")}
                    placeholder={t("tabForms.tab1.paymentTermsPlaceholder")}
                    autoComplete="off"
                    fullWidth
                    disabled={!tabsEditMode || loading}
                    onChange={handleInputChange}
                    error={validShortNumber(paymentTerms)}
                    helperText={
                      paymentTerms.length && validShortNumber(paymentTerms)
                        ? t("tabForms.tab1.paymentTermsHelperText")
                        : null
                    }
                  />
                </Grid>
                <Grid item className={rightFormStyles["form-item"]}>
                  <TextField
                    type="number"
                    value={lastYearAnnualSale}
                    id="lastYearAnnualSale"
                    name="lastYearAnnualSale"
                    label={t("tabForms.tab1.lastYearAnnualSale")}
                    placeholder={t(
                      "tabForms.tab1.lastYearAnnualSalePlaceholder"
                    )}
                    autoComplete="off"
                    fullWidth
                    disabled={!tabsEditMode || loading}
                    onChange={handleInputChange}
                    error={validString(lastYearAnnualSale, 1)}
                    helperText={
                      lastYearAnnualSale.length &&
                      validString(lastYearAnnualSale, 1)
                        ? t("tabForms.tab1.lastYearAnnualSaleHelperText")
                        : null
                    }
                  />
                </Grid>
              </Grid> */}

              {/* <Grid container spacing={4}>
                <Grid item className="w-100 mr-2">
                  <TextField
                    type="text"
                    value={otherFinancialIntegrityCertifications}
                    id="otherFinancialIntegrityCertifications"
                    name="otherFinancialIntegrityCertifications"
                    label={t(
                      "tabForms.tab1.otherFinancialIntegrityCertifications"
                    )}
                    placeholder={t(
                      "tabForms.tab1.otherFinancialIntegrityCertificationsPlaceholder"
                    )}
                    autoComplete="off"
                    fullWidth
                    disabled={!tabsEditMode || loading}
                    onChange={handleInputChange}
                    error={validString(
                      otherFinancialIntegrityCertifications,
                      5,
                      150
                    )}
                    helperText={
                      otherFinancialIntegrityCertifications.length &&
                      validString(otherFinancialIntegrityCertifications, 5, 150)
                        ? t(
                            "tabForms.tab1.otherFinancialIntegrityCertificationsHelperText"
                          )
                        : null
                    }
                  />
                </Grid>
              </Grid> */}

              <Grid container spacing={4}>
                <Grid item style={{ width: "33%" }}>
                  <p className={rightFormStyles["form-label"]}>
                    {t("tabForms.tab1.taxIdCardRFC")}
                  </p>
                  {tabsEditMode ? (
                    <FileDropzone
                      dataTestId="rfc-tax-id-dropzone"
                      label={
                        rfcTaxIdentificationCardUploadedFileName
                          ? rfcTaxIdentificationCardUploadedFileName
                          : t("tabForms.tab1.taxIdCardRFCPlaceholder")
                      }
                      disabled={loading}
                      fileTypesAccepted=".pdf"
                      onDrop={(files) =>
                        onDropFile(files, "rfcTaxIdentificationCard", 25)
                      }
                      onCancelUpload={onCancelUpload}
                    />
                  ) : (
                    <AttachedFile
                      dataTestId="rfc-tax-id-attached"
                      icon="pdf"
                      fileName={truncateString(
                        rfcTaxIdentificationCardName,
                        20
                      )}
                      fileUrl={rfcTaxIdentificationCardUrl}
                    />
                  )}
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <h5 className="mb-4">
                {t("tabForms.tab2.generalServiceDescription")}
              </h5>

              <Grid
                container
                spacing={4}
                className={rightFormStyles["margin-general-services"]}
              >
                {/* --- Campo nuevo --- */}
                <Grid container
                  justifyContent="center"
                  alignItems="center"
                  spacing={4}>
                  <Grid item xs={9}>
                    <TextField
                      type="text"
                      value={generalServiceDescription}
                      id="generalServiceDescription"
                      multiline
                      name="generalServiceDescription"
                      label=""
                      placeholder={t("tabForms.tab2.generalServiceDescriptionPlaceholder")}
                      autoComplete="off"
                      fullWidth
                      disabled={!tabsEditMode || loading}
                      onChange={handleInputChange}
                      // error={validString(street)}
                    />
                  </Grid>
                </Grid>
                
              </Grid>
            </TabPanel>

            <TabPanel value={tabValue} index={2}>
              <h5 className="mb-4">
                {tabsContent[tabValue].label[currentLanguage]}
              </h5>

              <Grid container spacing={4}>
                <Grid item className="w-100">
                  <TextField
                    type="text"
                    value={branchesLocation}
                    id="branchesLocation"
                    name="branchesLocation"
                    label={t("tabForms.tab3.branchesLocation")}
                    placeholder={t("tabForms.tab3.branchesLocationPlaceholder")}
                    autoComplete="off"
                    fullWidth
                    disabled={!tabsEditMode || loading}
                    onChange={handleInputChange}
                    error={validString(branchesLocation, 3, 150)}
                    helperText={
                      branchesLocation.length &&
                      validString(branchesLocation, 3, 150)
                        ? t("tabForms.tab3.branchesLocationHelperText")
                        : null
                    }
                  />
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel value={tabValue} index={3}>
              <h5 className="mb-4">
                {tabsContent[tabValue].label[currentLanguage]}
              </h5>

                {/* --- Campo nuevo --- */}
                <Grid container
                  justifyContent="center"
                  alignItems="center"
                  spacing={4}>
                  <Grid item xs={9}>
                  <FormLabel component="legend">
                    {t("tabForms.tab2.energeticClusterMember")}
                    <RadioGroup aria-label="gender" name="energeticClusterMember" value={energeticClusterMember} onChange={handleInputChange}>
                      <FormControlLabel value="si" control={<Radio />} label={t("common.yes")} disabled={!tabsEditMode || loading} />
                      <FormControlLabel value="no" control={<Radio />} label={t("common.no")} disabled={!tabsEditMode || loading}/>
                    </RadioGroup>
                  </FormLabel>
                  </Grid>
                </Grid>

            </TabPanel>
          </div>

          {displayEditActions ? (
            tabsEditMode ? (
              <div className="d-flex align-self-end">
                <Button
                  variant="contained"
                  className="font-weight-bold mt-4 mr-2 text-primary custom-secondary-btn"
                  onClick={() => cancelEditUserDetails()}
                  disabled={loading}
                >
                  {t("cancelUpdateButtonLabel")}
                </Button>

                <Button
                  color="primary"
                  variant="contained"
                  className="font-weight-bold mt-4 custom-primary-btn"
                  onClick={() => {
                    userToUpdate.brochure.base64 = "";
                    userToUpdate.brochure.fileName = "";
                    userToUpdate.companyLogo.base64 = "";
                    userToUpdate.companyLogo.fileName = "";
                    prepareUserUpdate(userToUpdate);
                  }}
                  disabled={loadingError || loading}
                >
                  {t("saveUpdateButtonLabel")}
                </Button>
              </div>
            ) : (
              <Button
                color="primary"
                variant="contained"
                className="font-weight-bold mt-4 align-self-end custom-primary-btn"
                onClick={() => setTabsEditMode(true)}
              >
                {t("updateButtonLabel")}
              </Button>
            )
          ) : null}

          <LoadingIndicator />
        </div>
      </Card>
    </>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </Typography>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default TabForms;
