import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Grid } from "@material-ui/core";

import { Alert, Loader } from "components";
import NoSessionNavigation from "../../components/NoSessionNavigation/NoSessionNavigation";
import ChooseServiceCard from "../../components/ChooseService/ChooseServiceCard";
import { Footer } from "../../layout-components";
import styles from "./choose-service.module.scss";

const ChooseService = ({ history }) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const { loading, error, eventInfo } = useSelector((store) => store.eventInfo);

  const goRegister = (selectedService) => {
    if (selectedService.customRegisterUrl) {
      window.open(selectedService.customRegisterUrl, "_blank");
    } else {
      history.push({
        pathname: "/register",
        state: { selectedService },
      });
    }
  };

  const goLogin = () => {
    history.push({
      pathname: "/login",
    });
  };

  return (
    <Grid
      container
      spacing={0}
      className="d-flex flex-column justify-content-between min-vh-100 nosession-site-bg-image"
      style={{
        backgroundImage: `linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)), url(${eventInfo.assets.noSessionSiteBg})`,
      }}
    >
      <NoSessionNavigation goBack={goLogin} />

      <Grid className={styles["main-content"]}>
        {error ? (
          <Grid item className="mx-4 d-flex justify-content-center">
            {error && <Alert severity="error" message={error} />}
          </Grid>
        ) : (
          <>
            <div className={styles["company-logo-container"]}>
              <img
                className={styles["company-logo-image"]}
                alt={eventInfo.name[currentLanguage]}
                src={eventInfo.assets.mainLogo}
              />
            </div>
            {/* REMOVE_HARDCODED: se pidio en energeticos2022 que no se mostrara este titulo */}
            {/* <h2 className="font-weight-bolder text-center">
              {`${eventInfo.title[currentLanguage]} - ${eventInfo.name[currentLanguage]}`}
            </h2> */}

            <div className={styles["cards-container"]}>
              {loading ? (
                <Loader />
              ) : eventInfo &&
                eventInfo.services &&
                eventInfo.services.length ? (
                eventInfo.services.map((service, index) => (
                  <ChooseServiceCard
                    key={index}
                    service={service}
                    onServiceSelect={goRegister}
                  />
                ))
              ) : (
                <p>No existen resultados</p>
              )}
            </div>
          </>
        )}
      </Grid>

      <div className="mt-4">
        <Footer />
      </div>
    </Grid>
  );
};

export default ChooseService;
