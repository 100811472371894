import axios from "config/axios";

const { REACT_APP_API_URL } = process.env;

const roleCodeGod = "RL000";
const roleCodeAdmin = "RL001";
const roleAssociate = "RL003";
const roleNonAssociate = "RL004";

const getBuyers = (pagination, sort, search = "", selectsFilters) => {
  const buyerId = "5f56bffa01a3ec3f2b16dcba";

  const payload = {
    ...pagination,
    selects: {
      firstName: 1,
      lastName: 1,
      email: 1,
      needs: 1,
      role: 1,
      companyId: 1,
    },
    filters: {
      ...selectsFilters,
      "role._id": buyerId,
      $or: [
        "firstName",
        "lastName",
        "email",
        "needs",
        "companyId.businessName",
      ],
    },
    sort,
    search,
  };
  return axios.post(`${REACT_APP_API_URL}/users/filters`, payload);
};
const getBuyersFromCompany = (
  pagination,
  sort,
  search = "",
  selectsFilters,
  companyId
) => {
  const buyerId = "5f56bffa01a3ec3f2b16dcba";

  const payload = {
    ...pagination,
    selects: {
      firstName: 1,
      lastName: 1,
      email: 1,
      createdAt: 1,
      contact: 1,
      videoConferenceUrl: 1,
      position: 1,
      needs: 1,
      tenant: 1,
      city: 1,
      "companyId._id": 1,
    },
    filters: {
      ...selectsFilters,
      "role._id": buyerId,
      "companyId._id": companyId,
      $or: ["firstName", "lastName", "email", "createdAt"],
    },
    sort,
    search,
  };
  return axios.post(`${REACT_APP_API_URL}/users/filters`, payload);
};
const getVendors = (pagination, sort, search = "", selectsFilters) => {
  const payload = {
    ...pagination,
    selects: {
      firstName: 1,
      lastName: 1,
      email: 1,
      comercialName: 1,
      needs: 1,
      role: 1,
      createdAt: 1,
    },
    filters: {
      ...selectsFilters,
      "service.isPremium": { $in: [true] },
      "role.code": {
        $in: [roleAssociate, roleNonAssociate],
      },
      $or: [
        "firstName",
        "lastName",
        "email",
        "needs",
        "comercialName",
        "createdAt",
      ],
    },
    sort,
    search,
  };

  return axios.post(`${REACT_APP_API_URL}/users/filters`, payload);
};

const activateVendor = (userid, authorized) => {
  return axios.put(`${REACT_APP_API_URL}/payment`, { userid, authorized });
};

const getBusiness = (pagination, sort, search = "", selectsFilters) => {
  const payload = {
    ...pagination,
    selects: {
      businessName: 1,
      comercialName: 1,
      rfc: 1,
    },
    filters: {
      ...selectsFilters,
      $or: ["businessName", "comercialName", "rfc"],
    },
    sort,
    search,
  };

  return axios.post(`${REACT_APP_API_URL}/company/filters`, payload);
};

const getUsers = (
  pagination,
  sort,
  search,
  userRole,
  loggedUserRoleId = ""
) => {
  const roleIdAdmin = "5f56beed42a3ec3f2b61923f";
  let preFilters = userRole
    ? {
        "role._id": userRole,
      }
    : loggedUserRoleId === roleIdAdmin
    ? {
        "role.code": {
          $nin: [roleCodeAdmin, roleCodeGod],
        },
      }
    : {};

  if (userRole === "5f56bf1942a3ec3f2b619244") {
    preFilters = {
      userPremiumTable: "",
    };
  }

  const payload = {
    ...pagination,
    selects: {
      _id: 1,
      firstName: 1,
      lastName: 1,
      email: 1,
      comercialName: 1,
      createdAt: 1,
      "role.name": 1,
      initialServiceId: 1,
      promoCode: 1,
    },
    filters: {
      ...preFilters,
      $or: [
        "firstName",
        "lastName",
        "email",
        "comercialName",
        "createdAt",
        "role.name",
        "initialServiceId",
      ],
    },
    sort,
    search,
  };
  return axios.post(`${REACT_APP_API_URL}/users/filters`, { ...payload });
};

const deleteUser = (userid) => {
  return axios.delete(`${REACT_APP_API_URL}/users/${userid}`);
};

const validatePayment = (serviceId, orderID, payerID) => {
  return axios.post(`${REACT_APP_API_URL}/validate-order`, {
    serviceId,
    orderID,
    payerID,
  });
};

export {
  getBuyers,
  deleteUser,
  getBuyersFromCompany,
  getVendors,
  getBusiness,
  activateVendor,
  getUsers,
  validatePayment,
};
