import {
  required,
  validEmail,
  minLength,
  maxLength,
  string,
  confirmSameValue,
} from ".";

export default (user, captchaPassed) => {
  const {
    firstName,
    lastName,
    email,
    emailConfirmation,
    findOut,
    typeOfUser,
    typeOfUserOther,
    findOutOther,
  } = user;

  return (
    !required(firstName) ||
    !minLength(3)(firstName) ||
    !maxLength(25)(firstName) ||
    !string(firstName) ||
    //
    !required(lastName) ||
    !minLength(3)(lastName) ||
    !maxLength(25)(lastName) ||
    !string(lastName) ||
    //
    !required(email) ||
    !validEmail(email) ||
    !maxLength(40)(email) ||
    !required(emailConfirmation) ||
    !validEmail(emailConfirmation) ||
    !confirmSameValue(email, emailConfirmation) ||
    //
    !required(typeOfUser) ||
    //
    !required(findOut) ||
    //
    (typeOfUserOther?.length ? !required(typeOfUserOther) : false) ||
    //
    (findOutOther?.length ? !required(findOutOther) : false) ||
    //
    !captchaPassed
  );
};
