import React from "react";
import { Switch, FormControlLabel } from "@material-ui/core";

const ToggleSwitch = ({ label, value, onCheckboxChecked }) => {
  return (
    <FormControlLabel
      control={
        <Switch
          checked={value}
          onChange={onCheckboxChecked}
          className="ml-3"
          color="primary"
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      }
      label={label}
    />
  );
};

export default ToggleSwitch;
