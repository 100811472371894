// ---Dependencies
import React from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
// ---Utils
import { required } from "../../../utils/validations";
// ---Constants
import { allOptions } from "./allOptions";

/**Component that builds a dynamic select input for register form with multilanguage support
 * @param {string} props.valueName Name of the key object intranslation file
 * @param {string} props.inputValue Controled value from parent component
 * @param {string} props.onChange Update method for controled value
 * @param {string} props.isDisabled Method for editing control
 * @returns {ReactElement} react component
 */
export default function ExtendedSelectField(props) {
  const { valueName, value: inputValue, onChange, isRequired, isDisabled } = props;
  const { t } = useTranslation("register");
  const options = allOptions[valueName].map((value) => ({
    value,
    display: t(`form.${valueName}.options.${value}`),
  }));

  return (
    <Grid container spacing={2} className={`p-2`}>
      <FormControl fullWidth>
        <InputLabel id={valueName}>{t(`form.${valueName}.label`)}</InputLabel>
        <Select
          name={valueName}
          labelId={valueName}
          onChange={onChange}
          defaultValue={inputValue}
          disabled={isDisabled}
          value={inputValue}
          isRequired  
          error={isRequired && !required(inputValue)}
          helperText={isRequired && !required(inputValue) ? t(`form.${valueName}.helper`): null}
        >
          {selectOptions(options)}
        </Select>
      </FormControl>
    </Grid>
  );
}

function selectOptions(options) {
  return options.map((option) => (
    <MenuItem value={option.value} key={option.value}>
      {option.display}
    </MenuItem>
  ));
}
