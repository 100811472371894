import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Card, Button } from "@material-ui/core";

import PaymentTransfer from "components/Register/payment-transfer";
import styles from "./successful-register.module.scss";
import formatNumberPrice from "../../../utils/format-number-price";

const SuccessfulRegister = ({ history }) => {
  const [email, setEmail] = useState("");
  const [serviceCode, setServiceCode] = useState("");
  const [isPaypalSelected, setIsPaypalSelected] = useState(false);
  const { eventInfo } = useSelector((store) => store.eventInfo);
  const [serviceSelect, setServiceSelect] = useState("");
  const goDashboard = () => {
    history.replace("/dashboard");
  };

  useEffect(() => {
    validateProps();
  });

  const validateProps = () => {
    if (
      history.location.state &&
      Object.keys(history.location.state).length &&
      typeof history.location.state.email === "string" &&
      typeof history.location.state.serviceCode === "string" &&
      typeof history.location.state.isPaypalSelected === "boolean"
    ) {
      const {
        email: stateEmail,
        serviceCode: stateServiceCode,
        // pass, // TODO: <--- pass not being used
        service,
        isPaypalSelected: stateIsPaypalSelected,
      } = history.location.state;
      setEmail(stateEmail);
      setServiceCode(stateServiceCode);
      setServiceSelect(service);
      setIsPaypalSelected(stateIsPaypalSelected);
    } else {
      history.push({ pathname: "/dashboard" });
    }
  };

  const RenderSuccessMessageWithTransferData = () => {
    const { isValidCupon, newAmount } = useSelector((store) => store.promos);
    const finalPrice = isValidCupon ? newAmount: serviceSelect.priceDescription;
    if (!eventInfo.paymentMethods) return <></>;
    return (
      <>
        <h5 className={styles["card-title"]}>Datos de pago</h5>
        <p className={styles["card-description"]}>
          ¡Tu registro ha sido exitoso! Por favor revisa bien los datos de pago.
          Una vez que hayas realizado tu pago, contacta al equipo de
          administración al correo <b>"{eventInfo.contactInformation.email}"</b>
          .
        </p>
        <p className={styles["card-description"]}>
          <b>Te hemos enviado un corrreo con una copia de los datos de pago.</b>
        </p>
        <PaymentTransfer transferData={eventInfo.paymentMethods[0]} />
        <div className="mt-4">
          <b>Monto total a Pagar</b>
          <h4>
            {formatNumberPrice(finalPrice)}{" "}
            {serviceSelect.priceDescription}
          </h4>
        </div>
      </>
    );
  };

  const RenderSuccessMessage = () => {
    return (
      <>
        <p className={styles["card-description"]}>
          Hemos recibido tu solicitud de registro. Ya puedes accesar a la
          plataforma. Te hemos enviado un correo de confirmación a:{" "}
          <b style={{ wordBreak: "break-all" }}>"{email}"</b>.
        </p>
        <p className={styles["card-description"]}>
          Para poder accesar a la sección de citas y compradores por favor
          reportar tu registro al correo{" "}
          <b>{eventInfo.contactInformation.email}</b> y para cualquier
          aclaración llamar al <b>{eventInfo.contactInformation.phone}</b>.
        </p>
      </>
    );
  };

  return (
    <>
      <h1 className={styles["successful-title"]}>Registro Exitoso</h1>

      <Card className={styles["card-container"]}>
        {/* Dynamic message */}
        {serviceCode === "SV000" || isPaypalSelected ? (
          <RenderSuccessMessage />
        ) : serviceCode === "SV001" ? (
          <RenderSuccessMessageWithTransferData />
        ) : null}

        <div className="text-center">
          <Button
            color="primary"
            variant="contained"
            className={`${styles["access-btn"]} custom-primary-btn`}
            onClick={goDashboard}
          >
            Accesar
          </Button>
        </div>
      </Card>
    </>
  );
};

export default SuccessfulRegister;
