import { useTranslation } from "react-i18next";
import React from "react";
import { Grid, Dialog, Button } from "@material-ui/core";

const RoleInfoDialog = ({
  dialog,
  toggleDialog,
  benefits,
  goRegister,
  color,
  isB2b,
  redirecUrl
}) => {
  const { t } = useTranslation("choose-service-card");
  return (
    <Dialog scroll="body" maxWidth="md" open={dialog} onClose={toggleDialog}>
      <Grid
        container
        spacing={0}
        style={{
          backgroundColor: color,
        }}
      >
        <Grid
          item
          xs={12}
          lg={5}
          className="d-flex align-self-center justify-content-center"
        />

        <Grid item>
          <div className="divider-v divider-v-md" />
        </Grid>

        <Grid item xs={12} lg={7}>
          <div className="bg-white ">
            <div className="p-4">
              <p className="font-size-lg text-black">{t("benefits")}</p>
              {benefits.map((benefit, index) => (
                <p key={index} className="font-size mb-3 text-black-50">
                  {benefit}
                </p>
              ))}

              <div className="text-right mt-2">
                <Button
                  style={{
                    backgroundColor: color,
                    color: "white",
                    fontWeight: "bold",
                    marginTop: "auto",
                    marginBottom: 10,
                  }}
                  onClick={isB2b ? redirecUrl :goRegister}
                >
                  {t("registerButton")}
                </Button>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </Dialog>
  );
};
export default RoleInfoDialog;
