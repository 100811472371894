import React from "react";
import { Dialog, Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ConfirmModal = ({
  icon,
  title,
  subtitle,
  actionButton,
  showDialog,
  toggleDialog,
}) => {
  return (
    <Dialog maxWidth="sm" open={showDialog} onClose={toggleDialog}>
      <div className="text-center p-5">
        <div className="avatar-icon-wrapper rounded-circle m-0">
          <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
            <FontAwesomeIcon
              icon={["fas", icon]}
              className="d-flex align-self-center display-3"
            />
          </div>
        </div>
        <h4 className="font-weight-bold mt-4">{title}</h4>
        {subtitle && <p className="mb-0 font-size-lg text-muted">{subtitle}</p>}
        <div className="pt-4">
          <Button
            onClick={toggleDialog}
            variant="outlined"
            color="secondary"
            className="mx-1"
          >
            <span className="btn-wrapper--label">Cancelar</span>
          </Button>
          {actionButton}
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmModal;
