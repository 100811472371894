import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";

import { FreeRegister, PremiumRegister } from "..";
import { NoSessionNavigation } from "../../components";
import { registerUser, reset } from "../../redux/actions";
import styles from "./register.module.scss";
import useUserCatalogs from "utils/catalogs/useUserCatalogs";

const Register = ({ location, history }) => {
  const { t } = useTranslation("register");
  const dispatch = useDispatch();
  const { success } = useSelector((store) => store.register);
  const { eventInfo } = useSelector((store) => store.eventInfo);
  const [isPaypalSelected, setIsPaypalSelected] = useState(false);
  const noSessionSiteBgUrl = eventInfo.assets.noSessionSiteBg;

  const [service, setService] = useState({ code: "" });
  const [renderContent, setRenderContent] = useState(null);
  const [userRegistered, setUserRegistered] = useState({});

  const { serviceId } = useUserCatalogs();

  // Set service or redirect if no service.code is present
  useEffect(() => {
    if (location.state && location.state.selectedService) {
      setService(location.state.selectedService);
      dispatch(reset());
    } else if (eventInfo.services && eventInfo.services[0]) {
      setService(eventInfo.services[0]);
      dispatch(reset());
    } else {
      history.push({ pathname: "/login" });
    }
  }, [dispatch, location, history, eventInfo]);
  // Set content according to service
  useEffect(() => {
    setRenderContent(<RenderContent />);
  }, [service]);
  // SUCCESSFULY REGISTER
  useEffect(() => {
    if (success) {
      // Redirect to successful free register page
      history.push({
        pathname: "/successful-register",
        state: {
          email: userRegistered.email,
          serviceCode: service.code,
          service,
          pass: userRegistered.password,
          isPaypalSelected,
        },
      });
    }
  }, [service, success, userRegistered, history, isPaypalSelected]);

  const onRegisterAction = (newUser, paypalSelected = false) => {
    setUserRegistered(newUser);
    setIsPaypalSelected(paypalSelected);
    dispatch(registerUser(newUser));
  };

  const RenderContent = () => {
    if (service && service._id) {
      if (service._id === serviceId.FREE_ASSISTANT) {
        return (
          <FreeRegister onRegisterAction={onRegisterAction} service={service} />
        );
      }
      if (service._id === serviceId.PREMIUM_ASSISTANT) {
        return (
          <PremiumRegister
            onRegisterAction={onRegisterAction}
            service={service}
          />
        );
      }
    }
    return (
      <Grid className="mt-4 text-center ml-auto mr-auto">
        <h1>{t("noServiceSelected")}</h1>
      </Grid>
    );
  };

  const goChooseService = () => {
    history.push({
      pathname: "/login",
    });
  };

  return (
    <Grid
      container
      spacing={0}
      className={styles["page-wrapper"] + " nosession-site-bg-image"}
      style={{
        backgroundImage: `url(${noSessionSiteBgUrl})`,
      }}
    >
      <NoSessionNavigation goBack={goChooseService} />

      {renderContent}
    </Grid>
  );
};

export default Register;
