import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { Button, Dialog, Tabs, Tab } from "@material-ui/core";

import DataTable from "components/DataTable";
import ModalMenu from "components/ModalMenu";
import PageLayout from "components/PageLayout";
import { getUsers, deleteUser } from "services/UserService";
import useUserCatalogs from "utils/catalogs/useUserCatalogs";
import { parseDate } from "utils/helpers/date";

import AddAssistant from "../../Assistant/AddAssistant";
import BodyImportUser from "./components/TabPanel/imports/BodyImportUser";
import ImportUsers from "./components/TabPanel/imports/ImportUsers";

const Users = ({ history, location }) => {
  const { t, i18n } = useTranslation("users");
  const currentLanguage = i18n.language;
  const { roleId, serviceCatalog } = useUserCatalogs();
  const { loggedUser } = useSelector((store) => store.loggedUser);

  const [selectedTab, setSelectedTab] = useState("");
  const [users, setBuyers] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(true);
  const [moduleLoading, setModuleLoading] = useState(false);
  const [error, setError] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);

  const [openImportUserModal, setOpenImportUserModal] = useState(false);

  const [openAddUserModal, setOpenAddUserModal] = useState(false);

  const dataTableColumns = [
    {
      name: { es: "NOMBRE", en: "NAME" },
      selector: "firstName",
      sortable: true,
      cell: (row) => <>{`${row.firstName} ${row.lastName}`}</>,
    },
    {
      name: { es: "NOMBRE COMERCIAL", en: "TRADE NAME" },
      selector: "comercialName",
      sortable: true,
    },
    {
      name: { es: "FECHA REGISTRO", en: "REGISTRATION DATE" },
      selector: "createdAt",
      sortable: true,
      cell: ({ createdAt }) => parseDate(createdAt),
    },
    {
      name: { es: "REGISTRO INICIAL", en: "INITIAL REGISTRATION" },
      selector: "initialServiceId",
      sortable: true,
      cell: (row) => {
        let serviceTitle = "N/A";
        Object.keys(serviceCatalog).forEach((key) => {
          if (row.promoCode && row.promoCode.displayName[currentLanguage]) {
            serviceTitle =
              row.promoCode.displayName[currentLanguage].toUpperCase();
          } else if (
            row.initialServiceId &&
            row.initialServiceId === serviceCatalog[key][currentLanguage].id
          ) {
            serviceTitle = serviceCatalog[key][currentLanguage].name;
          }
        });
        return serviceTitle;
      },
    },
    {
      name: { es: "ROL ACTUAL", en: "ACTUAL ROLE" },
      selector: `role[0].name[${currentLanguage}]`,
      sortable: true,
      cell: (row) => row.role[0].name[currentLanguage],
    },
    {
      name: { es: "ACCIONES", en: "ACTIONS" },
      cell: ({ _id, firstName, lastName }) => {
        const goToPublicProfile = () => {
          history.push("public-profile/" + _id);
        };
        const promptDeleteUser = () => {
          Swal.fire({
            title: `${t(
              "deleteUserModal.prompt1"
            )} ${firstName} ${lastName} ${t("deleteUserModal.prompt2")}`,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: t("deleteUserModal.promptConfirm"),
            cancelButtonText: t("deleteUserModal.promptCancel"),
          }).then((result) => {
            if (result.isConfirmed) {
              handleDeleteUser(_id);
            }
          });
        };
        const itemsMenu = [
          {
            name: { es: "Información del Usuario", en: "User information" },
            action: goToPublicProfile,
          },
          {
            name: { es: "Eliminar Usuario", en: "Delete User" },
            action: promptDeleteUser,
          },
        ];
        return <ModalMenu Items={itemsMenu} />;
      },
      grow: 0,
    },
  ];

  const tabsContent = [
    {
      value: "",
      label: {
        es: "Todos",
        en: "All",
      },
      tabWidth: "25%",
    },
    {
      value: roleId.BUYER,
      label: {
        es: "Compradores",
        en: "Buyers",
      },
      tabWidth: "25%",
    },
    {
      value: roleId.FREE_ASSISTANT,
      label: {
        es: "Proveedores Gratuitos",
        en: "Free Providers",
      },
      tabWidth: "25%",
    },
    {
      value: roleId.PREMIUM_ASSISTANT,
      label: {
        es: "Proveedores Premium",
        en: "Premium Providers",
      },
      tabWidth: "25%",
    },
  ];

  const handleDeleteUser = async (userid) => {
    setModuleLoading(true);
    try {
      const res = await deleteUser(userid);
      if (res.status === 200) {
        Swal.fire({
          icon: "success",
          title: t("deleteUserModal.confirmDeleteUser"),
          timer: 3000,
        });
      } else {
        throw res;
      }
    } catch ({ response }) {
      const msg =
        response && response.data.message
          ? response.data.message[currentLanguage]
          : t("deleteUserModal.confirmDeleteUserFailedText");
      Swal.fire({
        icon: "error",
        title: t("deleteUserModal.confirmDeleteUserFailed"),
        text: msg,
      });
    }
    setRefreshTable(!refreshTable);
    setModuleLoading(false);
  };

  const changeTabHandler = (_event, newTab) => {
    setSelectedTab(newTab);
  };

  useEffect(() => {
    setRefreshTable(!refreshTable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  const handleFilterChange = async (pagination, sorting, searchFilter) => {
    setLoading(true);
    try {
      const res = await getUsers(
        pagination,
        sorting,
        searchFilter,
        selectedTab,
        loggedUser.role._id
      );
      if (res.status === 200) {
        const { users, pagination } = res.data.data;
        setTotalRows(pagination.totalItems);
        setBuyers(users);
      } else {
        setError("Error interno de servidor");
      }
    } catch (error) {
      console.error(error);
      setError("Error interno de servidor");
    } finally {
      setLoading(false);
    }
  };

  const onSuccessAddUser = () => {
    if (openAddUserModal) {
      Swal.fire({
        icon: "success",
        title: "Datos actualizados exitosamente",
        timer: 3000,
      });
      setOpenAddUserModal(false);
      setRefreshTable(!refreshTable);
    }
  };

  return (
    <PageLayout
      titleHeading={t("pageTitle")}
      errorMessage="No hay usuarios en la base de datos"
      error={error}
      loading={moduleLoading}
    >
      <Tabs
        value={selectedTab}
        onChange={changeTabHandler}
        aria-label="Estatus de citas"
        style={{ marginBottom: "16px" }}
        indicatorColor="primary"
      >
        {tabsContent.map(({ value, label, tabWidth }, index) => (
          <Tab
            key={index}
            value={value}
            label={label[currentLanguage]}
            style={{ width: tabWidth }}
          />
        ))}
      </Tabs>
      <Dialog maxWidth="lg" open={openAddUserModal}>
        <AddAssistant
          onClose={() => setOpenAddUserModal(false)}
          onSuccess={() => {
            setRefreshTable(!refreshTable);
            onSuccessAddUser();
          }}
        />
      </Dialog>
      <Dialog maxWidth="lg" open={openImportUserModal}>
        <ImportUsers
          title={t("importUsersModal.title")}
          description={t("importUsersModal.description")}
          handleImportUserCancel={() => setOpenImportUserModal(false)}
        >
          <BodyImportUser
            handleImportUserCancel={() => setOpenImportUserModal(false)}
          />
        </ImportUsers>
      </Dialog>
      <div className="w-100 position-relative">
        <div className="w-100 d-flex justify-content-end position-absolute mt-3 pr-3">
          <Button
            className="mr-3"
            variant="contained"
            color="primary"
            onClick={() => setOpenImportUserModal(true)}
          >
            {t("importUsersButtonLabel")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenAddUserModal(true)}
          >
            {t("addUserButtonLabel")}
          </Button>
        </div>
      </div>
      <DataTable
        routeConfig={{
          history,
          locationSearch: location.search,
        }}
        refreshTable={refreshTable}
        columns={dataTableColumns}
        data={users}
        pagination
        paginationServer
        enableSearch
        keyField="_id"
        onFilterChange={handleFilterChange}
        paginationRowsPerPageOptions={[10, 20, 50, 100]}
        paginationTotalRows={totalRows}
        progressPending={loading}
      />
    </PageLayout>
  );
};

export default Users;
