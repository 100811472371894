import React from "react";
import { Container, Divider } from "@material-ui/core";
import styles from "./premium-register-resume.module.scss";

import {
  PremiumRegisterPayments,
  PremiumRegisterDetails,
} from "../../components";

const PremiumRegisterResume = ({ user, registerAction, service }) => {
  const onRegisterAction = (isPaypalSelected, orderID = "", payerID = "") => {
    registerAction({ ...user, orderID, payerID }, isPaypalSelected);
  };
  return (
    <Container>
      <Divider />
      <div className={styles["resume-container"]}>
        <div className="w-100 d-flex mb-4">
          <PremiumRegisterDetails user={user} />
        </div>
        <div className="w-100 d-flex">
          <PremiumRegisterPayments
            registerAction={onRegisterAction}
            service={service}
          />
        </div>
      </div>
    </Container>
  );
};

export default PremiumRegisterResume;
