import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { BeatLoader } from "react-spinners";
import { Grid, Button, TextField } from "@material-ui/core";

import { PhoneInput } from "components";
import tenantCode from "../../config/tenant";
import { reset } from "../../redux/actions";
import {
  required,
  validEmail,
  minLength,
  maxLength,
  string,
  numeric,
  validRfc,
  webpage,
} from "../../utils/validations";
import useUserCatalogs from "../../utils/catalogs/useUserCatalogs";

const BuyerForm = ({ registerLoading, onSubmit }) => {
  const { t } = useTranslation(["users", "buyer-form"]);
  const { t: p } = useTranslation("profile");
  const dispatch = useDispatch();

  const { roleId } = useUserCatalogs();

  // ----- State -----

  const [user, setUser] = useState({
    email: "",
    firstName: "",
    lastName: "",
    comercialName: "",
    companySocialName: "",
    companyWeb: "",
    position: "",
    phone: "",
    cellphone: "",
    role: roleId.BUYER,
    tenant: tenantCode,
    rfc: "",
    needs: "",
  });

  const disableRegisterBoolean =
    !required(user.firstName) ||
    !minLength(2)(user.firstName) ||
    !string(user.firstName) ||
    !required(user.lastName) ||
    !minLength(2)(user.lastName) ||
    !string(user.lastName) ||
    !required(user.email) ||
    !validEmail(user.email) ||
    !required(user.phone) ||
    !minLength(10)(user.phone) ||
    !maxLength(15)(user.phone) ||
    !numeric(user.phone) ||
    !required(user.cellphone) ||
    !minLength(10)(user.cellphone) ||
    !maxLength(15)(user.cellphone) ||
    !numeric(user.cellphone) ||
    !required(user.comercialName) ||
    !minLength(2)(user.comercialName) ||
    !string(user.comercialName) ||
    !required(user.companySocialName) ||
    !minLength(2)(user.companySocialName) ||
    !string(user.companySocialName) ||
    !validRfc(user.rfc) ||
    !required(user.needs) ||
    !minLength(2)(user.needs) ||
    !string(user.needs) ||
    !required(user.companyWeb) ||
    !minLength(3)(user.companyWeb) ||
    !webpage(user.companyWeb);
  // ----- Effects -----
  useEffect(() => {
    dispatch(reset());
  }, [dispatch]);

  // ----- Functions -----
  const handleInputChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleDialcodeChange = (index) => {
    return ({ dialCode }) => {
      setUser({ ...user, [index]: dialCode });
    };
  };

  const register = () => {
    onSubmit(user);
  };

  function notEmpty(value) {
    const isString = typeof value === 'string'
    const condition = value &&
                    isString &&
                    value.length &&
                    value.length >0 &&
                    value.length <3
    return condition
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} className="pt-1">
          <Grid container>
            <Grid item className="w-50 p-2">
              <TextField
                type="text"
                value={user.comercialName}
                name="comercialName"
                label={t("buyer-form:comercialName")}
                placeholder={t("buyer-form:comercialNamePlaceholder")}
                autoComplete="off"
                fullWidth
                onChange={handleInputChange}
                error={
                  user.comercialName.length > 0 &&
                  (!required(user.comercialName) ||
                    !minLength(2)(user.comercialName) ||
                    !string(user.comercialName))
                }
                helperText={
                  user.comercialName.length > 0
                    ? !required(user.comercialName) ||
                      !minLength(2)(user.comercialName) ||
                      !string(user.comercialName)
                      ? t("buyer-form:comercialNameHelperText")
                      : null
                    : null
                }
              />
            </Grid>
            <Grid item className="w-50 p-2">
              <TextField
                type="text"
                value={user.companySocialName}
                name="companySocialName"
                label={t("buyer-form:companySocialName")}
                placeholder={t("buyer-form:companySocialNamePlaceholder")}
                autoComplete="off"
                fullWidth
                onChange={handleInputChange}
                error={
                  user.companySocialName.length > 0 &&
                  (!required(user.companySocialName) ||
                    !minLength(2)(user.companySocialName) ||
                    !string(user.companySocialName))
                }
                helperText={
                  user.companySocialName.length > 0
                    ? !required(user.companySocialName) ||
                      !minLength(2)(user.companySocialName) ||
                      !string(user.companySocialName)
                      ? t("buyer-form:companySocialNameHelperText")
                      : null
                    : null
                }
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item className="w-50 p-2">
              <TextField
                type="text"
                value={user.companyWeb}
                name="companyWeb"
                label={t("buyer-form:companyWeb")}
                placeholder={t("buyer-form:companyWebPlaceholder")}
                autoComplete="off"
                fullWidth
                onChange={handleInputChange}
                error={
                  user.companyWeb.length > 0 &&
                  (!required(user.companyWeb) ||
                    !minLength(3)(user.companyWeb) ||
                    !webpage(user.companyWeb))
                }
                helperText={
                  user.companyWeb.length > 0
                    ? !required(user.companyWeb) ||
                      !minLength(3)(user.companyWeb) ||
                      !webpage(user.companyWeb)
                      ? t("buyer-form:companyWebHelperText")
                      : null
                    : null
                }
              />
            </Grid>

            <Grid item className="w-50 p-2">
              <TextField
                type="text"
                value={user.rfc}
                name="rfc"
                label={t("buyer-form:rfc")}
                placeholder={t("buyer-form:rfcPlaceholder")}
                autoComplete="off"
                fullWidth
                onChange={handleInputChange}
                error={
                  user.rfc.length > 0 &&
                  (!required(user.rfc) ||
                    !minLength(2)(user.rfc) ||
                    !validRfc(user.rfc))
                }
                helperText={
                  user.rfc.length > 0
                    ? !required(user.rfc) ||
                      !minLength(2)(user.rfc) ||
                      !validRfc(user.rfc)
                      ? t("buyer-form:rfcHelperText")
                      : null
                    : null
                }
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item className="w-50 p-2">
              <TextField
                type="text"
                value={user.firstName}
                name="firstName"
                label={t("buyer-form:firstName")}
                placeholder={t("buyer-form:firstNamePlaceholder")}
                autoComplete="off"
                fullWidth
                onChange={handleInputChange}
                error={
                  user.firstName.length > 0 &&
                  (!required(user.firstName) ||
                    !minLength(2)(user.firstName) ||
                    !string(user.firstName))
                }
                helperText={
                  user.firstName.length > 0
                    ? !required(user.firstName) ||
                      !minLength(2)(user.firstName) ||
                      !string(user.firstName)
                      ? t("buyer-form:firstNameHelperText")
                      : null
                    : null
                }
              />
            </Grid>
            <Grid item className="w-50 p-2">
              <TextField
                type="text"
                value={user.lastName}
                name="lastName"
                label={t("buyer-form:lastName")}
                placeholder={t("buyer-form:lastNamePlaceholder")}
                autoComplete="off"
                fullWidth
                onChange={handleInputChange}
                error={
                  user.lastName.length > 0 &&
                  (!required(user.lastName) ||
                    !minLength(2)(user.lastName) ||
                    !string(user.lastName))
                }
                helperText={
                  user.lastName.length > 0
                    ? !required(user.lastName) ||
                      !minLength(2)(user.lastName) ||
                      !string(user.lastName)
                      ? t("buyer-form:lastNameHelperText")
                      : null
                    : null
                }
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item className="w-50 p-2">
              <TextField
                type="email"
                value={user.email}
                name="email"
                label={t("buyer-form:email")}
                placeholder={t("buyer-form:emailPlaceholder")}
                autoComplete="off"
                fullWidth
                onChange={handleInputChange}
                error={
                  user.email.length > 0 &&
                  (!required(user.email) || !validEmail(user.email))
                }
                helperText={
                  user.email.length > 0
                    ? !required(user.email) || !validEmail(user.email)
                      ? t("buyer-form:emailHelperText")
                      : null
                    : null
                }
              />
            </Grid>
            <Grid item className="w-50 p-2">
              <TextField
                type="text"
                value={user.position}
                name="position"
                label={t("buyer-form:position")}
                placeholder={t("buyer-form:positionPlaceholder")}
                autoComplete="off"
                fullWidth
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4} className="mt-1">
            <Grid item className="w-50 p-2">
              <PhoneInput
                inputLabel={t("buyer-form:phone")}
                value={user.phone}
                valueName="phone"
                previousState={user}
                setValue={setUser}
                placeholder={t("buyer-form:phonePlaceholder")}
                error={
                  user.phone.length > 0 &&
                  (!required(user.phone) ||
                    !minLength(10)(user.phone) ||
                    !maxLength(15)(user.phone) ||
                    !numeric(user.phone))
                }
                helperText={
                  user.phone.length > 0
                    ? !required(user.phone) ||
                      !minLength(10)(user.phone) ||
                      !maxLength(15)(user.phone) ||
                      !numeric(user.phone)
                      ? t("buyer-form:phoneHelperText")
                      : null
                    : null
                }
                setCountryData={handleDialcodeChange("phoneDialCode")}
              />
            </Grid>
            <Grid item className="w-50 p-2">
              <PhoneInput
                inputLabel={t("buyer-form:cellphone")}
                value={user.cellphone}
                valueName="cellphone"
                previousState={user}
                setValue={setUser}
                placeholder={t("buyer-form:cellphonePlaceholder")}
                error={
                  user.cellphone.length > 0 &&
                  (!required(user.cellphone) ||
                    !minLength(10)(user.cellphone) ||
                    !maxLength(15)(user.cellphone) ||
                    !numeric(user.cellphone))
                }
                helperText={
                  user.cellphone.length > 0
                    ? !required(user.cellphone) ||
                      !minLength(10)(user.cellphone) ||
                      !maxLength(15)(user.cellphone) ||
                      !numeric(user.cellphone)
                      ? t("buyer-form:cellphoneHelperText")
                      : null
                    : null
                }
                setCountryData={handleDialcodeChange("cellphoneDialCode")}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item className="w-50 p-2">
              <TextField
                type="text"
                value={user.needs}
                name="needs"
                label={t("buyer-form:needs")}
                placeholder={t("buyer-form:needsPlaceholder")}
                autoComplete="off"
                fullWidth
                onChange={handleInputChange}
                error={
                  user.needs.length > 0 &&
                  (!required(user.needs) || !minLength(2)(user.needs))
                }
                helperText={
                  user.needs.length > 0
                    ? !required(user.needs) || !minLength(2)(user.needs)
                      ? t("buyer-form:needsHelperText")
                      : null
                    : null
                }
              />
            </Grid>
            <Grid item className="w-50 p-2">
              <TextField
                type="text"
                value={user.paymentConditions}
                id="paymentConditions"
                name="paymentConditions"
                label={p("rightForm.paymentConditions")}
                placeholder={p("rightForm.paymentConditionsPlaceholder")}
                autoComplete="off"
                fullWidth
                error={notEmpty(user.paymentConditions)}
                helperText={notEmpty(user.paymentConditions) && t("rightForm.paymentConditionsPlaceholder") }
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item className="w-100">
              <TextField
                type="text"
                value={user.techRequirements}
                id="techRequirements"
                name="techRequirements"
                label={p("rightForm.techRequirements")}
                placeholder={p("rightForm.techRequirementsPlaceholder")}
                autoComplete="off"
                fullWidth
                error={notEmpty(user.techRequirements)}
                helperText={notEmpty(user.techRequirements) && t("rightForm.techRequirementsPlaceholder") }
                multiline
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container justifyContent="flex-end" className="mt-2">
        <Grid item>
          {registerLoading ? (
            <BeatLoader color={"var(--primary)"} loading={true} />
          ) : (
            <>
              <div>
                <Button
                  color="primary"
                  size="large"
                  variant="contained"
                  className="mb-2 custom-primary-btn"
                  disabled={disableRegisterBoolean || registerLoading}
                  onClick={register}
                >
                  {t("users:addUserModal.registerButtonLabel")}
                </Button>
              </div>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default BuyerForm;
