export const allOptions = {
  gender: ["MALE", "FEMALE", "OTHER", "NOT_SPECIFIC"],
  sector: [
    "TI",
    "MANUFATURE",
    "FINANCES",
    "HEALTH",
    "GOBERMENT",
    "COMERCE",
    "EDUCATION",
    "OTHER",
  ],
  findOut: ["EMAIL", "SOCIAL_NETWORKS", "RECOMENDATION", "OTHER"],
  typeOfUser: ["STUDENT", "INVESTIGATOR", "LABOR", "TEACHER", "OTHER"],
  needs: ["RENT", "BUY", "INVEST", "DEVELOPER", "SUPPLIER", "OTHER"],
};
