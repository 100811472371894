import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";

import ReactPayPal from "../ReactPayPal";
import { validatePayment } from "services/UserService";
import { paymentSuccess } from "../../redux/actions";

const PaypalMethod = ({ serviceId, price, closePaymentModal }) => {
  const { t } = useTranslation("register");
  const [selectedValue, setSelectedValue] = React.useState(true);
  const { eventInfo } = useSelector((store) => store.eventInfo);
  const dispatch = useDispatch();

  // create function to handle paypal payment
  const handlePaypalPayment = async (orderID, payerID) => {
    try {
      const response = await validatePayment(serviceId, orderID, payerID);
      closePaymentModal();
      Swal.fire({
        icon: "success",
        title: t("paymentSuccess.title"),
        text: t("paymentSuccess.message"),
      });
      dispatch(paymentSuccess(response.data.data));
    } catch (error) {
      closePaymentModal();
      Swal.fire({
        icon: "error",
        title: t("paymentError.title"),
        text:
          t("paymentError.message") +
          eventInfo.contactInformation.paymentsEmail,
      });
    }
  };

  return (
    <div
      className="w-100 mt-2 mr-4"
      style={{
        border: "solid 2px lightgray",
        padding: "10px",
        paddingLeft: "30px",
        paddingRight: "30px",
        position: "relative",
        top: "9px",
      }}
    >
      <div className="d-flex align-items-center">
        <FontAwesomeIcon
          icon={["fab", "paypal"]}
          className="font-size-xxl mr-4 text-primary"
        />

        <Typography
          className="pb-2"
          style={{
            position: "relative",
            top: "9px",
            fontWeight: "bold",
            fontSize: "12px",
          }}
        >
          {t("payments.paypal")}
        </Typography>
        <div style={{ flexGrow: 1 }}></div>
        <FontAwesomeIcon
          icon={"angle-up"}
          color="gray"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setSelectedValue(!selectedValue);
          }}
        />
      </div>
      <div
        style={{ width: "100%", marginTop: "30px" }}
        className={selectedValue ? "" : "d-none"}
      >
        <ReactPayPal price={price} emitRegisterAction={handlePaypalPayment} />
      </div>
    </div>
  );
};
export default PaypalMethod;
