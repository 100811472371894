// ---Requests
import axios from "../../config/axios";
import handleAxiosError from "../../utils/handleAxiosError";
// ---Types
import { APLY_CUPON, EDIT_CODE, RESTART_CODE, CLEAR_STATE } from './types';

export function checkCode(promoCode, serviceId) {
  return async dispatch => {
    try {
      const response = await axios.post("/service/validatePromo",{ promoCode, serviceId })
      if(response.status === 200){
        const action = {
          type: APLY_CUPON,
          payload: {
            ...response.data.data, 
            swalType: "success",
            isValidCupon: true
          }
        }
        dispatch(action)
      }
      else {
        const action = {
          type: APLY_CUPON,
          payload: { swalType: "error", isValidCupon: false, newAmount: undefined }
        }
        dispatch(action)
      }
    } catch (error) {
      handleAxiosError(error)
      const action = {
        type: APLY_CUPON,
        payload: { swalType: "error", isValidCupon: false, newAmount: undefined }
      }
      dispatch(action)
    }
  }
}

export function updateCode(newCode) {
  return dispatch =>{
    const action = {
      type: EDIT_CODE,
      payload: newCode
    }
    dispatch(action)
    const resetCupon = {
      type: APLY_CUPON,
      payload: { isValidCupon: false }
    }
    dispatch(resetCupon)
  }
}

export function restartCode(promoCode, serviceId) {
  return async dispatch => {
    try {
      const response = await axios.post("/service/validatePromo",{ promoCode, serviceId })
      if(response.status === 200){
        const action = {
          type: RESTART_CODE,
          payload: {
            ...response.data.data, 
            swalType: undefined,
          }
        }
        dispatch(action)
      }
    } catch (error) {
      handleAxiosError(error)
      const action = {
        type: RESTART_CODE,
        payload: { swalType: undefined }
      }
      dispatch(action)
    }
  }
}
export function clearPromoState() {
  return dispatch => {
        const action = {
          type: CLEAR_STATE,
        }
        dispatch(action)
      }
  }