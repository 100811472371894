import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TextField, Button, Grid } from "@material-ui/core";

import { Loader, Alert, Recaptcha } from "..";
import TermsConditions from "./terms-conditions";
import styles from "./register-form.module.scss";
import {
  required,
  validEmail,
  minLength,
  maxLength,
  string,
  confirmSameValue,
  disableRegisterFlag,
} from "../../utils/validations";
import ExtendedSelectField from "./ExtendedSelectField";
import CountriesInputV2 from "components/Countries-input/Countries-input-v2";

const defaultUserDataObj = {
  firstName: "",
  lastName: "",
  email: "",
  emailConfirmation: "",
  country: "",
  typeOfUser: "",
  findOut: "",
};

const RegisterForm = ({
  onRegisterAction,
  premiumHandleNext,
  editUser = false,
  noSubmit = false,
  initialServiceId,
}) => {
  const { i18n, t } = useTranslation("register");
  const currentLanguage = i18n.language;
  const { loading, error } = useSelector((store) => store.register);
  const { newAmount } = useSelector((store) => store.promos);
  const [selected, setSelected] = useState("");
  const [captchaPassed, setCaptchaPassed] = useState(false);
  const captchaSucceededHandler = () => setCaptchaPassed(true);
  const captchaFailedHandler = () => setCaptchaPassed(false);
  const showNextLabel = premiumHandleNext && newAmount !== 0;
  const label = showNextLabel
    ? { es: "Siguiente", en: "Next" }
    : { es: "Regístrate", en: "Register" };

  const [user, setUser] = useState(defaultUserDataObj);

  useEffect(() => {
    if (editUser) {
      const completeUser = {
        ...editUser,
        cellphone: editUser.cellphoneDialCode + editUser.cellphone,
      };
      setUser(completeUser);
    }
  }, [editUser]);

  const {
    firstName,
    lastName,
    email,
    emailConfirmation,
    typeOfUser,
    typeOfUserOther,
    findOut,
    findOutOther,
  } = user;

  const disableRegisterFormFlag = disableRegisterFlag(
    user,
    !noSubmit ? captchaPassed : true
  );

  const handleInputChange = (inputEvent) => {
    setUser({ ...user, [inputEvent.target.name]: inputEvent.target.value });
  };

  const submitAction = () => {
    const isOtherType = typeOfUser !== "OTHER";
    const isOtherFind = findOut !== "OTHER";

    const newUser = {
      ...user,
      initialServiceId,
      country: selected,
      cellphone: "",
      typeOfUser: isOtherType ? typeOfUser : typeOfUserOther,
      findOut: isOtherFind ? findOut : findOutOther,
    };
    if (showNextLabel) {
      premiumHandleNext(newUser);
    } else {
      onRegisterAction(newUser, false);
    }
  };

  // If noSubmit, then hide Captcha, Terms & Conditions and Submit btn
  const hideSubmitItemsClass = noSubmit ? "d-none" : "";
  return (
    <>
      <div className={styles["form-container"]}>
        <TextField
          type="text"
          value={firstName}
          name="firstName"
          label={t("form.firstName")}
          placeholder={t("form.firstNamePlaceholder")}
          autoComplete="off"
          className={styles["text-field"]}
          fullWidth
          onChange={handleInputChange}
          error={
            firstName && firstName.length > 0
              ? !required(firstName) ||
                !minLength(3)(firstName) ||
                !maxLength(25)(firstName) ||
                !string(firstName)
              : false
          }
          helperText={
            firstName && firstName.length > 0
              ? !required(firstName) ||
                !minLength(3)(firstName) ||
                !maxLength(25)(firstName) ||
                !string(firstName)
                ? t("form.firstNameError")
                : null
              : null
          }
        />

        <TextField
          type="text"
          value={lastName}
          name="lastName"
          label={t("form.lastName")}
          placeholder={t("form.lastNamePlaceholder")}
          autoComplete="off"
          className={styles["text-field"]}
          fullWidth
          onChange={handleInputChange}
          error={
            lastName && lastName.length > 0
              ? !required(lastName) ||
                !minLength(3)(lastName) ||
                !maxLength(25)(lastName) ||
                !string(lastName)
              : false
          }
          helperText={
            lastName && lastName.length > 0
              ? !required(lastName) ||
                !minLength(3)(lastName) ||
                !maxLength(25)(lastName) ||
                !string(lastName)
                ? t("form.lastNameError")
                : null
              : null
          }
        />

        <TextField
          type="email"
          value={email}
          name="email"
          label={t("form.email")}
          placeholder={t("form.emailPlaceholder")}
          autoComplete="off"
          className={styles["text-field"]}
          fullWidth
          onChange={handleInputChange}
          error={
            email && email.length > 0
              ? !required(email) || !validEmail(email) || !maxLength(50)(email)
              : false
          }
          helperText={
            email && email.length > 0
              ? !required(email) || !validEmail(email) || !maxLength(50)(email)
                ? t("form.emailError")
                : null
              : null
          }
        />
        <TextField
          type="email"
          value={emailConfirmation}
          name="emailConfirmation"
          label={t("form.emailConfirmation")}
          placeholder={t("form.emailConfirmationPlaceholder")}
          autoComplete="off"
          className={styles["text-field"]}
          fullWidth
          onChange={handleInputChange}
          error={
            emailConfirmation.length > 0
              ? !required(emailConfirmation) ||
                !validEmail(emailConfirmation) ||
                !confirmSameValue(email, emailConfirmation)
              : false
          }
          helperText={
            emailConfirmation.length > 0
              ? !required(emailConfirmation) ||
                !validEmail(emailConfirmation) ||
                !confirmSameValue(email, emailConfirmation)
                ? t("form.emailConfirmationError")
                : null
              : null
          }
        />
        <CountriesInputV2
          inputLabel={t("form.country")}
          setValue={setSelected}
        />
        <ExtendedSelectField
          value={typeOfUser}
          valueName={"typeOfUser"}
          onChange={handleInputChange}
        />
        {typeOfUser === "OTHER" && (
          <TextField
            value={typeOfUserOther}
            name="typeOfUserOther"
            fullWidth
            type="text"
            autoComplete="off"
            style={{ marginTop: "10px" }}
            className={styles["text-field-other"]}
            onChange={handleInputChange}
            error={
              typeOfUserOther && typeOfUserOther.length > 0
                ? !minLength(2)(typeOfUserOther) ||
                  !maxLength(40)(typeOfUserOther) ||
                  !string(typeOfUserOther)
                : false
            }
            helperText={
              typeOfUserOther && typeOfUserOther.length > 0
                ? !minLength(2)(typeOfUserOther) ||
                  !maxLength(40)(typeOfUserOther) ||
                  !string(typeOfUserOther)
                  ? t("form.typeOfUser.helper")
                  : null
                : null
            }
          />
        )}
        <ExtendedSelectField
          value={findOut}
          valueName={"findOut"}
          onChange={handleInputChange}
        />
        {findOut === "OTHER" && (
          <TextField
            value={findOutOther}
            name="findOutOther"
            fullWidth
            type="text"
            autoComplete="off"
            style={{ marginTop: "10px" }}
            className={styles["text-field-other"]}
            onChange={handleInputChange}
            error={
              findOutOther && findOutOther.length > 0
                ? !minLength(2)(findOutOther) ||
                  !maxLength(40)(findOutOther) ||
                  !string(findOutOther)
                : false
            }
            helperText={
              findOutOther && findOutOther.length > 0
                ? !minLength(2)(findOutOther) ||
                  !maxLength(40)(findOutOther) ||
                  !string(findOutOther)
                  ? t("form.findOut.helper")
                  : null
                : null
            }
          />
        )}
        <Grid
          className={`${hideSubmitItemsClass}`}
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "34px",
          }}
        >
          <Recaptcha
            captchaSucceededHandler={captchaSucceededHandler}
            captchaFailedHandler={captchaFailedHandler}
          />
        </Grid>

        <div className="mt-2 mb-3">
          <small className="text-dark">{t("missingFields")}</small>
        </div>
      </div>

      <Grid
        container
        justifyContent="center"
        className={`mt-3 mb-3 ${hideSubmitItemsClass}`}
      >
        <TermsConditions />
      </Grid>
      <div className="d-flex justify-content-center mb-4">
        {loading ? (
          <Loader />
        ) : (
          <Button
            className="text-white custom-primary-btn"
            color="primary"
            variant="contained"
            disabled={disableRegisterFormFlag || loading}
            onClick={submitAction}
          >
            {label[currentLanguage]}
          </Button>
        )}
      </div>

      {error ? <Alert severity="error" message={error} /> : null}
    </>
  );
};

export default RegisterForm;
