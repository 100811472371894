import React, { useEffect } from "react";
import Swal from "sweetalert2";
import { Card } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import styles from "./profile.module.scss";
import convertToBase64 from "../../utils/convertToBase64";
import { LeftResume, RightForm, TabForms } from "../../components";
import PageLayout from "components/PageLayout";
import { uploadUserAvatar, reset, loginSuccess } from "../../redux/actions";

const NewProfile = () => {
  // -- State --
  const { t } = useTranslation("profile");
  const dispatch = useDispatch();

  const { loggedUser } = useSelector((store) => store.loggedUser);

  const { error, loading, uploadSuccess, newAvatar } = useSelector(
    (store) => store.user
  );
  const { error: userDetailsError } = useSelector((store) => store.userDetails);

  const {
    appointments: { scheduled, pending, userlimitAppointments, limitAppointments },
  } = loggedUser;
  

  // -- Effects --
  const displayAssistantTabs = displayTabs();

  // Update user left-resume avatar on header and sidebar without refresh with updated response only in own profile
  useEffect(() => {
    if (uploadSuccess) {
      dispatch(loginSuccess({ ...loggedUser, avatar: newAvatar }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadSuccess]);

  useEffect(() => {
    if (error || userDetailsError) {
      Swal.fire({
        icon: "error",
        title: "Error al actualizar datos del perfil",
        text: "Por favor intente más tarde",
      });
      dispatch(reset());
    }
  }, [error, userDetailsError, dispatch]);

  // -- Functions --
  function displayTabs() {
    let isAssistant = false;
    if (loggedUser && loggedUser.service) {
      const {
        service: { isAssistant: x },
      } = loggedUser;
      isAssistant = x;
    }
    let isGodOrBuyer = false
    if(loggedUser && loggedUser.role && loggedUser.role.code){
      const { code } = loggedUser.role;
      isGodOrBuyer = code === 'RL005' || code === 'RL000'
    }
    const showTabs = isAssistant && !isGodOrBuyer
    return showTabs
  }
  const onAvatarSelected = async (e) => {
    const selectedFile = e.target.files[0];
    let base64Avatar = undefined;
    try {
      base64Avatar = await convertToBase64(selectedFile);
      uploadAvatar(base64Avatar);
    } catch (error) {
      throw new Error(error);
    }
  };
  const uploadAvatar = (userAvatarConverted) => {
    const userId = loggedUser._id;
    dispatch(uploadUserAvatar(userId, userAvatarConverted));
  };

  return (
    <PageLayout titleHeading={t("pageTitle")}>
      <div className={styles["content-wrapper"]}>
        <Card className={styles["new-profile-wrapper"]}>
          <div className={styles["resume-form-container"]}>
            <LeftResume
              user={loggedUser}
              appointmentCounters={{
                scheduled,
                pending,
                userlimitAppointments,
                limitAppointments,
              }}
              onAvatarSelected={onAvatarSelected}
              loading={loading}
            />

            <div className={styles["resume-form-separator"]} />

            <RightForm />
          </div>
        </Card>

        {displayAssistantTabs ? <TabForms /> : null}
      </div>
    </PageLayout>
  );
};

export default NewProfile;
