/* eslint-disable react-hooks/exhaustive-deps */
import LanguageIcon from '@material-ui/icons/Language';
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import ModalMenu from "components/ModalMenu";

const SwitchLanguage = ({ inHeader = true }) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const fontColor = inHeader ? "#FFF" : "#000";

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);

    localStorage.setItem("currentLanguage", language);
  };

  /** Get saved language from localStorage and preselect option in header and all site */
  useEffect(() => {
    const currentLanguageSaved = localStorage.getItem("currentLanguage") || "";
    if (currentLanguageSaved) i18n.changeLanguage(currentLanguageSaved);
  }, []);

  return (
    <>
      <ModalMenu
        Items={[
          {
            name: "Español",
            action: () => changeLanguage("es"),
          },
          {
            name: "English",
            action: () => changeLanguage("en"),
          },
        ]}
        buttonText={currentLanguage.charAt(0).toUpperCase() + currentLanguage.slice(1)}
        buttonTextStyles={{
          border: `1px solid ${fontColor}`,
          fontWeight: "bolder",
          color: fontColor,
        }}
        buttonIcon={LanguageIcon}
      />
    </>
  );
};

export default SwitchLanguage;
