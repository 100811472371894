import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Grid, Card } from "@material-ui/core";
import CameraAltOutlined from "@material-ui/icons/CameraAltOutlined";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

import styles from "./dashboard.module.scss";
import { PageTitle } from "../../components";
import useWindowSize from "utils/custom-hooks/useWindowSize";

export default function Dashboard() {
  const windowSize = useWindowSize();
  const { t, i18n } = useTranslation("dashboard");
  const currentLanguage = i18n.language;
  const paymentStatus = sessionStorage.getItem("paymentStatus");

  const { eventInfo } = useSelector((store) => store.eventInfo);

  const dashboardImages = eventInfo.assets.dashboardImages || [];

  if (paymentStatus !== null && +paymentStatus === 0) {
    Swal.fire({
      icon: "warning",
      title: t("payment.paymentFailTitle"),
      text:
        t("payment.paymentFail") + eventInfo.contactInformation.paymentsEmail,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }

  const RenderDescriptionParagraphs = () => {
    const longDescription = eventInfo.longDescription[currentLanguage];

    // If text has more than 2 lines in eventinfo json file, it has to be separated with the | character
    if (longDescription) {
      return longDescription.split("|").map((paragraph, index) => {
        if (paragraph) {
          return (
            <div className={styles["card-paragraph"]} key={index}>
              {`${paragraph}`}
            </div>
          );
        }
        return null;
      });
    }

    return null;
  };

  return (
    <>
      <PageTitle showPageTitleIconBox={false} titleHeading={t("pageTitle")} />
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} m={6} lg={8}>
          <Card className={styles["card-container"]}>
            <RenderDescriptionParagraphs />

            <div className={styles["card-images-container"]}>
              {dashboardImages.map((image, imageIndex) => (
                <div
                  key={`dashboard-images-${imageIndex}`}
                  className={styles["image-container"]}
                >
                  <img alt="hola" className={styles["card-image"]} src={image} />
                </div>
              ))}
            </div>
          </Card>

          {(windowSize === "xsm" || windowSize === "sm") && (
            <div className="d-flex justify-content-center">
              <NavLink to="/VirtualTour">
                <Button
                  variant="contained"
                  color="primary"
                  className="custom-primary-btn"
                >
                  <div className="mr-2">
                    <CameraAltOutlined />
                  </div>
                  <b>Tour Virtual</b>
                </Button>
              </NavLink>
            </div>
          )}
        </Grid>
      </Grid>
    </>
  );
}
