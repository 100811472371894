import User from "./User";
import UserDetails from "./user-details";
import Role from "./Role";
import EventInfo from "./EventInfo";
import Services from "./Services";
import LoggedUser from "./LoggedUser";
import Register from "./Register";
import DynamicForm from "./DynamicForm";
import BuyerCalendar from "./BuyerCalendar";
import Buyers from "./Buyers";
import Catalogues from "./Catalogues";
import ThemeOptions from "./ThemeOptions";
import AdditionalInfo from "./AdditionalInfo";
import Company from "./Company";
import Sellers from "./Sellers";
import Appointment from "./Appointment";
import PendingAppointments from "./PendingAppointments";
import ValidationUserProfile from "./ValidationUserProfile";
import LevelUser from "./Levels";
import PromoReducer from '../promos/reducer';

export default {
  ThemeOptions,
  loggedUser: LoggedUser,
  register: Register,
  user: User,
  userDetails: UserDetails,
  role: Role,
  eventInfo: EventInfo,
  services: Services,
  dynamicForm: DynamicForm,
  buyerCalendar: BuyerCalendar,
  buyers: Buyers,
  catalogues: Catalogues,
  additionalInfo: AdditionalInfo,
  company: Company,
  sellers: Sellers,
  appointment: Appointment,
  pendingAppointments: PendingAppointments,
  validationuserprofile: ValidationUserProfile,
  levels: LevelUser,
  promos: PromoReducer
};
