import { useTranslation } from "react-i18next";
import React from "react";
import { Grid, Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { SwitchLanguage } from "components";

/**
 *
 * @param {function} goBack Should be passed props.history.goBack
 * @param {string} label Text label for button
 * @param {object} containerStyle Styles for grid container
 */
const NoSessionNavigation = ({
  goBack,
  containerStyle = "",
  backButton = true,
}) => {
  const { t } = useTranslation("login");
  return (
    <Grid item className="m-4 d-flex justify-content-between" style={{ ...containerStyle }}>
      {backButton ? (
        <Button
          size="large"
          variant="contained"
          className="text-black"
          onClick={() => goBack()}
        >
          <span className="btn-wrapper--icon">
            <FontAwesomeIcon icon={["fas", "arrow-left"]} />
          </span>
          <span className="btn-wrapper--label">{t("navigation.backButton")}</span>
        </Button>
      ) : <div />}
      <div>
        <SwitchLanguage inHeader={false}/>
      </div>
    </Grid>
  );
};

export default NoSessionNavigation;
