import { APLY_CUPON, EDIT_CODE, RESTART_CODE, CLEAR_STATE } from "./types";

const initialState = {
  isValidCupon: false,
  newAmount: undefined,
  name: undefined,
  displayName: undefined,
  code: undefined,
  isFree: undefined,
  isActive: undefined,
  swalType: undefined, // success | error
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case EDIT_CODE:
      return {
        ...state,
        code: payload,
      };

    case APLY_CUPON:
      return {
        ...state,
        ...payload,
      };

    case RESTART_CODE:
      return {
        ...state,
        ...payload,
      };
    case CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
}
