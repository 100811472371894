import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { NavLink as RouterLink, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Box,
  Badge,
  Menu,
  Button,
  List,
  ListItem,
  Divider,
} from "@material-ui/core";

import { SwitchLanguage } from "components";
import { logoutUser } from "../../redux/actions";
import defaultUser from "../../assets/images/avatars/user-default.png";

const StyledBadge = withStyles({
  badge: {
    backgroundColor: "var(--success)",
    color: "var(--success)",
    boxShadow: "0 0 0 2px #fff",
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
})(Badge);

const HeaderUserBox = withRouter(({ history, props, className }) => {
  const { t } = useTranslation("header");
  const dispatch = useDispatch();

  const { loggedUser } = useSelector((store) => store.loggedUser);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOut = () => {
    dispatch(logoutUser());
    history.push("/login");
  };

  return (
    <Fragment>
      <Button
        color="inherit"
        onClick={handleClick}
        className={`text-capitalize px-3 text-left btn-inverse d-flex align-items-center ${className}`}
      >
        <Box>
          <StyledBadge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            variant="dot"
          >
            <Avatar
              sizes="44"
              alt={loggedUser.firstName}
              src={loggedUser.avatar || defaultUser}
            />
          </StyledBadge>
        </Box>
        <div className="d-none d-xl-block pl-3">
          <div className="font-weight-bold pt-2 line-height-1">
            {loggedUser.firstName} {loggedUser.lastName}
          </div>
          <span className="text-white-50"></span>
        </div>
        <span className="pl-1 pl-xl-3">
          <FontAwesomeIcon icon={["fas", "angle-down"]} className="opacity-5" />
        </span>
      </Button>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        onClose={handleClose}
        className="ml-2"
      >
        <div className="dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
          <List className="text-left bg-transparent d-flex align-items-center flex-column pt-0">
            <Box>
              <StyledBadge
                overlap="circular"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                variant="dot"
              >
                <Avatar
                  sizes="44"
                  alt="User"
                  src={loggedUser.avatar || defaultUser}
                />
              </StyledBadge>
            </Box>
            <div className="pl-3 text-center">
              <div className="font-weight-bold text-center pt-2 line-height-1">
                {loggedUser.firstName} {loggedUser.lastName}
              </div>
              <span className="text-black-50 text-center">
                {/* {loggedUser.role.name} */}
              </span>
            </div>
            <Divider className="w-100 mt-2" />
            <ListItem button component={RouterLink} to="/profile">
              {t("profileLinkLabel")}
            </ListItem>

            <ListItem button onClick={logOut}>
              {t("logoutLinkLabel")}
            </ListItem>
          </List>
        </div>
      </Menu>
    </Fragment>
  );
});

export default HeaderUserBox;
