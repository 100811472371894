/* eslint-disable react-hooks/exhaustive-deps */
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";

import PageLayout from "components/PageLayout";
import DataTable from "components/DataTable";
import ModalMenu from "components/ModalMenu";
import {
  getAssistantUsers,
  activateAssistantUsers,
} from "services/AssistantsService";
import { getCatalogues } from "redux/actions/Catalogues";
import useUserCatalogs from "utils/catalogs/useUserCatalogs";

const ActivateAssistants = ({ history, location }) => {
  const { serviceCatalog } = useUserCatalogs();

  const { t, i18n } = useTranslation("activate-assistants");
  const currentLanguage = i18n.language;
  const dispatch = useDispatch();
  const { catalogues } = useSelector((store) => store.catalogues);

  const [assistants, setAssistants] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [error, setError] = useState(false); TODO: Is this gonna be used?
  const [totalRows, setTotalRows] = useState(0);
  const [tableLoading, setTableLoading] = useState(true);
  const [refreshTable, setRefreshTable] = useState([]);

  const dataTableColumns = [
    {
      name: { es: "NOMBRE", en: "NAME" },
      selector: "firstName",
      sortable: true,
      cell: (row) => <>{`${row.firstName} ${row.lastName}`}</>,
    },
    {
      name: { es: "CORREO ELECTRONICO", en: "EMAIL" },
      selector: "email",
      sortable: true,
    },
    {
      name: { es: "EMPRESA", en: "COMPANY" },
      selector: "comercialName",
      sortable: true,
    },
    {
      name: { es: "PUESTO", en: "POSITION" },
      selector: "position",
      sortable: true,
    },
    {
      name: { es: "TELÉFONO", en: "CELLPHONE" },
      selector: "contact.cellphone",
      sortable: true,
    },
    {
      name: { es: "REGISTRO INICIAL", en: "INITIAL REGISTRATION" },
      selector: "initialServiceId",
      sortable: true,
      cell: (row) => {
        let serviceTitle = "N/A";
        Object.keys(serviceCatalog).forEach((key) => {
          if (row.promoCode && row.promoCode.displayName[currentLanguage]) {
            serviceTitle =
              row.promoCode.displayName[currentLanguage].toUpperCase();
          } else if (
            row.initialServiceId &&
            row.initialServiceId === serviceCatalog[key][currentLanguage].id
          ) {
            serviceTitle = serviceCatalog[key][currentLanguage].name;
          }
        });
        return serviceTitle;
      },
    },
    {
      name: { es: "SERVICIO", en: "SERVICE" },
      selector: `service[0].cardTitle[${currentLanguage}]`,
      sortable: true,
    },
    {
      name: { es: "ACCIONES", en: "ACTIONS" },
      cell: (row) => {
        const actionItem = (serviceid, cardTitle) => {
          const message = `${t("activateModal1")}${row.firstName}${t(
            "activateModal2"
          )}${cardTitle}?`;
          Swal.fire({
            title: message,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No",
          }).then((result) => {
            if (result.isConfirmed) {
              changeAssistantStatus(row._id, serviceid);
            }
          });
        };
        const itemsMenu = catalogues.providerServices
          ? catalogues.providerServices.map((providerService) => ({
              name: providerService.cardTitle[currentLanguage],
              action: () =>
                actionItem(
                  providerService._id,
                  providerService.cardTitle[currentLanguage]
                ),
              disabled: row.service[0]._id === providerService._id,
            }))
          : [];
        return <ModalMenu Items={itemsMenu} />;
      },
      grow: 0,
    },
  ];

  useEffect(() => {
    dispatch(getCatalogues(["usersComercialNames", "providerServices"]));
  }, []);

  /** Query assistants */
  const handleFilterChange = async (
    pagination,
    sorting,
    searchFilter,
    selectsFilters
  ) => {
    setTableLoading(true);
    try {
      const dataVendors = await getAssistantUsers(
        pagination,
        sorting,
        searchFilter,
        selectsFilters
      );
      if (dataVendors.status === 200) {
        const data = dataVendors.data.data.users;
        if (!data.length && searchFilter === "") {
        } else {
          setAssistants(dataVendors.data.data.users);
          setTotalRows(dataVendors.data.data.pagination.totalItems);
        }
      } else {
        // setError("Error interno de servidor");
      }
    } catch (error) {
      console.error(error);
      // setError("Error interno de servidor");
    } finally {
      setTableLoading(false);
    }
  };

  /** Update Assistan status */

  const changeAssistantStatus = async (userId, serviceid) => {
    setLoading(true);
    try {
      await activateAssistantUsers(userId, serviceid);
      setRefreshTable(refreshTable + 1);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error al actualizar el servicio",
        text: "Por favor intente más tarde",
      });
    }
    setLoading(false);
  };

  return (
    <PageLayout
      emptyDataMessage="No se encontraron proveedores registrados"
      loading={loading}
      titleHeading={t("pageTitle")}
    >
      <DataTable
        routeConfig={{
          history,
          locationSearch: location.search,
        }}
        refreshTable={refreshTable}
        columns={dataTableColumns}
        filterFields={[
          {
            displayName: { es: "EMPRESA", en: "COMPANY" },
            selector: "comercialName",
            catalog: catalogues.usersComercialNames,
          },
        ]}
        data={assistants}
        pagination
        paginationServer
        enableSearch
        keyField="_id"
        onFilterChange={handleFilterChange}
        paginationRowsPerPageOptions={[10, 20, 50, 100]}
        paginationTotalRows={totalRows}
        progressPending={tableLoading}
      />
    </PageLayout>
  );
};

export default ActivateAssistants;
