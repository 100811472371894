import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import formatNumberPrice from "../../utils/format-number-price";
import styles from "./ChooseServiceCard.module.scss";
import ChooseServiceCardBenefitsList from "./ChooseServiceCardBenefitsList";
// redux
import { useDispatch } from "react-redux";
import { clearPromoState } from "redux/promos/action";


const ChooseServiceCard = ({
  service,
  onServiceSelect,
  modalDashboard,
  contact,
}) => {
  // ---------------const, hooks and states
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation("choose-service-card");
  const currentLanguage = i18n.language;

  const {
    benefits,
    cardTitle,
    title,
    color,
    price,
    priceWithIVA,
    isB2b,
    isPremium,
    notes,
  } = service;

  const serviceSelect = () => {
    onServiceSelect(service);
    dispatch(clearPromoState())
  };
  const redirecUrl = () => {
    window.open("https://b2b-soft2022.boletia.com/", "_blank");
  };
  const renderNotes = () => {
    if (notes[currentLanguage] && notes[currentLanguage].length) {
      return (
        <div
          className="text-center my-2 font-weight-bold"
          style={{ fontSize: "14px" }}
        >
          <p>{t("importantText")}</p>
          {notes[currentLanguage].map((note, index) => (
            <p key={index}>{note}</p>
          ))}
        </div>
      );
    }
    return null;
  };

  return (
    <div className="d-flex justify-content-center">
      <div className={styles["card-container"]}>
        <div
          className={styles["card-color-line"]}
          style={{ backgroundColor: color }}
        />
        <div className={styles["card-text-content"]}>
          <h2 className="text-center font-weight-bold">
            {cardTitle[currentLanguage]}
          </h2>
          <p className={styles["card-description-label"]}>
            {title[currentLanguage]}
          </p>
          <p
            className={styles["card-price-label"]}
            style={{ color, fontSize: "30px" }}
          >
            {isB2b || !isPremium
              ? ""
              : !priceWithIVA
              ? ` ${t("isFree")}`
              : `${t("price")} ${formatNumberPrice(price)} ${t("priceDes")}`}
          </p>

          <b style={{ fontSize: 14 }}>{t("benefits")}</b>
          <ChooseServiceCardBenefitsList
            benefits={benefits[currentLanguage]}
            actionRegister={serviceSelect}
            color={color}
            isB2b={isB2b}
            redirecUrl={redirecUrl}
          />
          {renderNotes()}
          <Button
            size="large"
            style={{
              backgroundColor: color,
              color: "white",
              fontWeight: "bold",
              marginTop: "auto",
              marginBottom: 10,
              width: "56%",
            }}
            onClick={isB2b ? redirecUrl : serviceSelect}
          >
            {modalDashboard ? t("titleButtonDashboard") : t("titleButton")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ChooseServiceCard;
