import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import {
  Button,
  Card,
  Divider,
  Stepper,
  Step,
  StepLabel,
} from "@material-ui/core";

import styles from "./premium-register.module.scss";
import { RegisterForm, PremiumRegisterResume } from "../../../components";

const PremiumRegister = ({ onRegisterAction, service }) => {
  const { t } = useTranslation("register");
  const [newUser, setNewUser] = useState(false);

  const [activeStep, setActiveStep] = useState(0);
  const steps = [t("title"), t("payment")];

  const registerAction = (newUser, isPaypalSelected = false) => {
    onRegisterAction(newUser, isPaypalSelected);
  };

  const premiumHandleNext = (user) => {
    setNewUser(user);
    handleNext();
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <RegisterForm
            onRegisterAction={registerAction}
            premiumHandleNext={premiumHandleNext}
            editUser={newUser}
            noSubmit
            initialServiceId={service._id}
            usePromoCode
          />
        );
      case 1:
        return (
          <PremiumRegisterResume
            user={newUser}
            registerAction={registerAction}
            service={service}
          />
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <Card className={styles["card-container"]}>
      <div className="font-size-lg font-weight-bold">
        {t("title")}
      </div>

      <Divider className="mt-2 mb-4" />

      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => {
          return (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div>
        <div className="w-100">
          <div className="w-100">{getStepContent(activeStep)}</div>
          <div className="d-flex justify-content-between">
            {activeStep > 0 && (
              <Button
                color="primary"
                variant="contained"
                className="text-white custom-primary-btn"
                onClick={handleBack}
              >
                {t("backButton")}
              </Button>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default PremiumRegister;
