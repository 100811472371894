import { useTranslation } from "react-i18next";
import React from "react";
import { Button, Grid, TextField, MenuItem } from "@material-ui/core";

import { PhoneInput, AttachedFile } from "../../..";
import styles from "../right-resume.module.scss";
import useUserCatalogs from "utils/catalogs/useUserCatalogs";

const FullSeller = ({ user, openAppointmentRequest }) => {
  const { t, i18n } = useTranslation(["profile", "public-profile"]);
  const currentLanguage = i18n.language;

  const {
    firstName,
    lastName,
    position,
    email,
    contact: { phone, cellphone },
    // personType,
    needs,
    comercialName,
    businessName,
    rfc,
    companyWeb,
    companyPresentation: {
      brochure: { name: brochureName, url: brochureUrl },
      companyLogo: { name: companyLogoName, url: companyLogoUrl },
    },
    // techRequirements,
    // paymentConditions,
    // youtube,
    // providerType,
  } = user;

  const { personTypes, providerTypes } = useUserCatalogs();

  return (
    <div data-testid="full-seller-data">
      <Grid container spacing={4}>
        <Grid item className="w-50">
          <TextField
            type="text"
            value={firstName}
            name="firstName"
            label={t("profile:rightForm.firstName")}
            placeholder={t("profile:rightForm.firstNamePlaceholder")}
            autoComplete="off"
            fullWidth
            disabled
          />
        </Grid>
        <Grid item className="w-50">
          <TextField
            type="text"
            value={lastName}
            name="lastName"
            label={t("profile:rightForm.lastName")}
            placeholder={t("profile:rightForm.lastNamePlaceholder")}
            autoComplete="off"
            fullWidth
            disabled
          />
        </Grid>
      </Grid>

      <Grid container spacing={4} className="mt-1">
        <Grid item className="w-50">
          <TextField
            type="text"
            value={position}
            name="position"
            label={t("profile:rightForm.position")}
            placeholder={t("profile:rightForm.positionPlaceholder")}
            autoComplete="off"
            fullWidth
            disabled
          />
        </Grid>

        <Grid item className="w-50">
          <TextField
            type="email"
            value={email}
            name="email"
            label={t("profile:rightForm.email")}
            placeholder={t("profile:rightForm.emailPlaceholder")}
            autoComplete="off"
            fullWidth
            disabled
          />
        </Grid>
      </Grid>

      <Grid container spacing={4} className="mt-1">
        <Grid item className="w-50">
          <PhoneInput
            inputLabel={t("profile:rightForm.phone")}
            value={phone ? `${phone}` : ""}
            valueName="phone"
            previousState={user}
            placeholder={t("profile:rightForm.phonePlaceholder")}
            disabled
          />
        </Grid>
        <Grid item className="w-50">
          <PhoneInput
            inputLabel={t("profile:rightForm.cellphone")}
            value={`${cellphone}`}
            valueName="cellphone"
            previousState={user}
            placeholder={t("profile:rightForm.cellphonePlaceholder")}
            disabled
          />
        </Grid>
      </Grid>

      <Grid container spacing={4} className="mt-1">
        <Grid item className="w-50">
          <TextField
            type="text"
            value={comercialName}
            name="comercialName"
            label={t("profile:rightForm.comercialName")}
            placeholder={t("profile:rightForm.comercialNamePlaceholder")}
            fullWidth
            disabled
          />
        </Grid>
        {/* <Grid item className="w-50">
          <TextField
            name="personType"
            label={t("profile:rightForm.personType")}
            fullWidth
            disabled
            select
            value={personType}
          >
            {personTypes.array.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label[currentLanguage]}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
       */}
      </Grid>

      <Grid container spacing={4} className="mt-1">
        <Grid item className="w-50">
          <TextField
            type="text"
            value={businessName}
            name="businessName"
            label={t("profile:rightForm.businessName")}
            placeholder={t("profile:rightForm.businessNamePlaceholder")}
            autoComplete="off"
            fullWidth
            disabled
          />
        </Grid>
        <Grid item className="w-50">
          <TextField
            type="text"
            value={rfc}
            name="rfc"
            label={t("profile:rightForm.rfc")}
            placeholder={t("profile:rightForm.rfcPlaceholder")}
            autoComplete="off"
            fullWidth
            disabled
          />
        </Grid>
      </Grid>

      <Grid container spacing={4} className="mt-1">
        <Grid item className="w-50">
          <TextField
            type="text"
            value={companyWeb}
            name="companyWeb"
            label={t("profile:rightForm.companyWeb")}
            placeholder={t("profile:rightForm.companyWebPlaceholder")}
            autoComplete="off"
            fullWidth
            disabled
          />
        </Grid>
        {/* <Grid item className="w-50">
          <TextField
            type="text"
            value={youtube}
            id="youtube"
            name="youtube"
            label={t("profile:rightForm.youtube")}
            placeholder={t("profile:rightForm.youtubePlaceholder")}
            autoComplete="off"
            fullWidth
            disabled
          />
        </Grid> */}
        {/* <Grid container spacing={4} className="mt-1">
        <Grid item className="w-50">
          <TextField
            id="providerType"
            name="providerType"
            label={t("profile:rightForm.providerType")}
            fullWidth
            disabled
            select
            value={providerType}
          >
            {providerTypes.array.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid> */}
        {/* <Grid item className="w-50">
          <TextField
              type="text"
              value={paymentConditions}
              id="paymentConditions"
              name="paymentConditions"
              label={t("profile:rightForm.paymentConditions")}
              placeholder={t("profile:rightForm.paymentConditionsPlaceholder")}
              autoComplete="off"
              fullWidth
              disabled
            />
        </Grid> */}
      </Grid>
      <Grid container spacing={4} className="mt-1">
        {/* <Grid item className="w-100">
          <TextField
              type="text"
              value={techRequirements}
              id="techRequirements"
              name="techRequirements"
              label={t("profile:rightForm.techRequirements")}
              placeholder={t("profile:rightForm.techRequirementsPlaceholder")}
              autoComplete="off"
              fullWidth
              disabled
            />
        </Grid> */}
      </Grid>
      <Grid container spacing={4} className="mt-1">
        <Grid item className="w-100">
          <TextField
            type="text"
            value={needs}
            name="needs"
            label={t("profile:rightForm.needsProvider")}
            placeholder={t("profile:rightForm.needsProviderPlaceholder")}
            autoComplete="off"
            maxRows="4"
            multiline
            fullWidth
            disabled
          />
        </Grid>
      </Grid>

      <Grid container spacing={4} className="mt-3">
        <Grid item className="w-50">
          <p className={styles["form-label"]}>
            {t("profile:rightForm.brochureName")}
          </p>
          <AttachedFile
            icon="pdf"
            fileName={brochureName}
            fileUrl={brochureUrl}
          />
        </Grid>

        <Grid item className="w-50">
          <p className={styles["form-label"]}>
            {t("profile:rightForm.companyLogo")}
          </p>
          {companyLogoUrl ? (
            <div
              style={{
                height: "80px",
                maxHeight: "80px",
                margin: "0 auto",
              }}
            >
              <img
                alt={companyLogoName}
                src={companyLogoUrl}
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
            </div>
          ) : (
            <small className="text-dark font-italic">
              {t("profile:rightForm.noFile")}
            </small>
          )}
        </Grid>
      </Grid>
      {openAppointmentRequest && (
        <Button
          color="primary"
          variant="contained"
          className="font-weight-bold mt-4 align-self-end custom-primary-btn"
          onClick={openAppointmentRequest}
        >
          {t("public-profile:b2b.requestOpenModalBtn")}
        </Button>
      )}
    </div>
  );
};

export default FullSeller;
