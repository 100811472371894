import React, { useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";

const Recaptcha = (props) => {
  const { i18n } = useTranslation("public-profile");
  const currentLanguage = i18n.language;
  const [lang, setLang] = useState(currentLanguage);
  const CAPTCHA_KEY = process.env.REACT_APP_CAPTCHA_KEY || "";

  const { captchaSucceededHandler, captchaFailedHandler } = props;

  const onChangeHandler = (value) => {
    if (value) {
      captchaSucceededHandler();
    }
  };
  useEffect(() => {
    setLang(currentLanguage);
  }, [currentLanguage]);

  const onExpiredHandler = () => captchaFailedHandler();

  const onErroredHandler = () => captchaFailedHandler();

  if (CAPTCHA_KEY) {
    return (
      <ReCAPTCHA
        key={lang}
        sitekey={CAPTCHA_KEY}
        onChange={onChangeHandler}
        onExpired={onExpiredHandler}
        onErrored={onErroredHandler}
        hl={lang}
      />
    );
  }

  return <b className="text-danger">Captcha key is missing</b>;
};

export default Recaptcha;
