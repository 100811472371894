// ******************** RESET ********************
export const RESET = "RESET";

// ******************** ADDITIONAL INFO ********************
// -- ADD Additional info --
export const ADDITIONAL_INFO = "ADDITIONAL_INFO";
export const ADDITIONAL_INFO_FAILED = "ADDITIONAL_INFO_FAILED";
export const ADDITIONAL_INFO_SUCCESS = "ADDITIONAL_INFO_SUCCESS";

// ******************** BUYERS ********************
// ----- BUYERS -----
export const BUYERS = "BUYERS";
export const BUYERS_FAILED = "BUYERS_FAILED";
export const BUYERS_SUCCESS = "BUYERS_SUCCESS";
// ----- BUYER -----
export const BUYER = "BUYER";
export const BUYER_FAILED = "BUYER_FAILED";
export const BUYER_SUCCESS = "BUYER_SUCCESS";
// ----- CREATE BUYER -----
export const REGISTER_BUYER_FAILED = "REGISTER_BUYER_FAILED";
export const REGISTER_BUYER_SUCCESS = "REGISTER_BUYER_SUCCESS";

// ******************** BUYER_CALENDAR ********************
export const BUYER_CALENDAR = "BUYER_CALENDAR";
export const BUYER_CALENDAR_FAILED = "BUYER_CALENDAR_FAILED";
export const BUYER_CALENDAR_SUCCESS = "BUYER_CALENDAR_SUCCESS";

// ----- GET CATALOGUES -----
export const CATALOGUES = "CATALOGUES";
export const CATALOGUES_FAILED = "CATALOGUES_FAILED";
export const CATALOGUES_SUCCESS = "CATALOGUES_SUCCESS";

// ******************** COMPANIES ********************
// -- GET companies --
export const COMPANIES = "COMPANIES";
export const COMPANIES_FAILED = "COMPANIES_FAILED";
export const COMPANIES_SUCCESS = "COMPANIES_SUCCESS";
// -- GET company --
export const COMPANY = "COMPANY";
export const COMPANY_FAILED = "COMPANY_FAILED";
export const COMPANY_SUCCESS = "COMPANY_SUCCESS";
// -- ADD company --
export const ADD_COMPANY = "ADD_COMPANY";
export const ADD_COMPANY_FAILED = "ADD_COMPANY_FAILED";
export const ADD_COMPANY_SUCCESS = "ADD_COMPANY_SUCCESS";
// -- UPDATE company --
export const EDIT_COMPANY = "EDIT_COMPANY";
export const EDIT_COMPANY_FAILED = "EDIT_COMPANY_FAILED";
export const EDIT_COMPANY_SUCCESS = "EDIT_COMPANY_SUCCESS";
// -- UPDATE company avatar --
export const UPLOAD_COMPANY_AVATAR = "UPLOAD_COMPANY_AVATAR";
export const UPLOAD_COMPANY_AVATAR_FAILED = "UPLOAD_COMPANY_AVATAR_FAILED";
export const UPLOAD_COMPANY_AVATAR_SUCCESS = "UPLOAD_COMPANY_AVATAR_SUCCESS";

// ******************** DYNAMIC FORM ********************
export const DYNAMIC_FORM = "DYNAMIC_FORM";
export const DYNAMIC_FORM_FAILED = "DYNAMIC_FORM_FAILED";
export const DYNAMIC_FORM_SUCCESS = "DYNAMIC_FORM_SUCCESS";

// ******************** REGISTER ********************
export const REGISTER = "REGISTER";
export const REGISTER_FAILED = "REGISTER_FAILED";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
// -- Register confirmation --
export const CONFIRM = "CONFIRM";
export const CONFIRM_FAILED = "CONFIRM_FAILED";
export const CONFIRM_SUCCESS = "CONFIRM_SUCCESS";
// -- Recover password --
export const RECOVER_PASS = "RECOVER_PASS";
export const RECOVER_PASS_FAILED = "RECOVER_PASS_FAILED";
export const RECOVER_PASS_SUCCESS = "RECOVER_PASS_SUCCESS";
// -- Change password --
export const CHANGE_PASS = "CHANGE_PASS";
export const CHANGE_PASS_FAILED = "CHANGE_PASS_FAILED";
export const CHANGE_PASS_SUCCESS = "CHANGE_PASS_SUCCESS";

// ******************** ROLES ********************
// -- GET roles --
export const ROLES = "ROLES";
export const ROLES_FAILED = "ROLES_FAILED";
export const ROLES_SUCCESS = "ROLES_SUCCESS";
// ******************** Event Info ********************
// -- GET services --
export const EVENT_INFO = "EVENT_INFO";
export const EVENT_INFO_FAILED = "EVENT_INFO_FAILED";
export const EVENT_INFO_SUCCESS = "EVENT_INFO_SUCCESS";
// ******************** Services ********************
// -- GET services --
export const SERVICES = "SERVICES";
export const SERVICES_FAILED = "SERVICES_FAILED";
export const SERVICES_SUCCESS = "SERVICES_SUCCESS";

// ******************** SESSION ********************
// -- LOGIN --
export const LOGIN = "LOGIN";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const PAYMENT_SUCCESS = "PAYMENT_SUCCESS";

// ******************** USERS ********************
// -- GET users --
export const USERS = "USERS";
export const USERS_FAILED = "USERS_FAILED";
export const USERS_SUCCESS = "USERS_SUCCESS";
// -- GET user --
export const USER = "USER";
export const USER_FAILED = "USER_FAILED";
export const USER_SUCCESS = "USER_SUCCESS";
// -- UPLOAD/UPDATE user avatar --
export const UPLOAD_AVATAR = "UPLOAD_AVATAR";
export const UPLOAD_AVATAR_FAILED = "UPLOAD_AVATAR_FAILED";
export const UPLOAD_AVATAR_SUCCESS = "UPLOAD_AVATAR_SUCCESS";
// -- Add user --
export const ADD_USER = "ADD_USER";
export const ADD_USER_FAILED = "ADD_USER_FAILED";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
// -- Update user self --
export const UPDATE_USER_SELF = "UPDATE_USER_SELF";
export const UPDATE_USER_SELF_FAILED = "UPDATE_USER_SELF_FAILED";
export const UPDATE_USER_SELF_SUCCESS = "UPDATE_USER_SELF_SUCCESS";
// -- Update user self details --
export const UPDATE_USER_SELF_DETAILS = "UPDATE_USER_SELF_DETAILS";
export const UPDATE_USER_SELF_DETAILS_FAILED =
  "UPDATE_USER_SELF_DETAILS_FAILED";
export const UPDATE_USER_SELF_DETAILS_SUCCESS =
  "UPDATE_USER_SELF_DETAILS_SUCCESS";
// -- Update user by admin --
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_FAILED = "UPDATE_USER_FAILED";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
// -- Update user self details by admin--
export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";
export const UPDATE_USER_DETAILS_FAILED = "UPDATE_USER_DETAILS_FAILED";
export const UPDATE_USER_DETAILS_SUCCESS = "UPDATE_USER_DETAILS_SUCCESS";

export const UPDATE_USER_POST_SUCCESS = "UPDATE_USER_POST_SUCCESS";

// ******************** SELLERS ********************
// ----- GET seller -----
export const SELLER = "SELLER";
export const SELLER_FAILED = "SELLER_FAILED";
export const SELLER_SUCCESS = "SELLER_SUCCESS";
// ----- POST sellers -----
export const SELLERS = "SELLERS";
export const SELLERS_FAILED = "SELLERS_FAILED";
export const SELLERS_SUCCESS = "SELLERS_SUCCESS";

export const SELLERS_SUCCESS_ADMIN = "SELLERS_SUCCESS_ADMIN";
// ----- GET request appointment availability -----
export const REQUEST_APPOINTMENT_AVAILABILITY =
  "REQUEST_APPOINTMENT_AVAILABILITY";
export const REQUEST_APPOINTMENT_AVAILABILITY_FAILED =
  "REQUEST_APPOINTMENT_AVAILABILITY_FAILED";
export const REQUEST_APPOINTMENT_AVAILABILITY_SUCCESS =
  "REQUEST_APPOINTMENT_AVAILABILITY_SUCCESS";
// ******************** APPOINTMENTS ********************
// ----- GET appointment by id -----
export const GET_APPOINTMENT = "GET_APPOINTMENT";
export const GET_APPOINTMENT_FAILED = "GET_APPOINTMENT_FAILED";
export const GET_APPOINTMENT_SUCCESS = "GET_APPOINTMENT_SUCCESS";
// -- REQUEST appointment (from vendor to buyer) --
export const REQUEST_APPOINTMENT = "REQUEST_APPOINTMENT";
export const REQUEST_APPOINTMENT_FAILED = "REQUEST_APPOINTMENT_FAILED";
export const REQUEST_APPOINTMENT_SUCCESS = "REQUEST_APPOINTMENT_SUCCESS";
// -- UPDATE appointment (change status) --
export const UPDATE_APPOINTMENT_STATUS = "UPDATE_APPOINTMENT_STATUS";
export const UPDATE_APPOINTMENT_STATUS_FAILED =
  "UPDATE_APPOINTMENT_STATUS_FAILED";
export const UPDATE_APPOINTMENT_STATUS_SUCCESS =
  "UPDATE_APPOINTMENT_STATUS_SUCCESS";
// -- ADD appointment (only by admins) --
export const ADD_APPOINTMENT = "ADD_APPOINTMENT";
export const ADD_APPOINTMENT_FAILED = "ADD_APPOINTMENT_FAILED";
export const ADD_APPOINTMENT_SUCCESS = "ADD_APPOINTMENT_SUCCESS";

// -- Pending Appointments --
export const PENDING_APPOINTMENTS = "PENDING_APPOINTMENTS";
export const PENDING_APPOINTMENTS_FAILED = "PENDING_APPOINTMENTS_FAILED";
export const PENDING_APPOINTMENTS_SUCCESS = "PENDING_APPOINTMENTS_SUCCESS";

// --Button duplicate ---
export const VALIDATION_PROFILE = "VALIDATION_PROFILE";

export const PAGE = "PAGE";
export const PAGEAPPOINTMENT = "PAGEAPPOINTMENT";

// --Update Level ---
export const UPDATELEVEL = "UPDATELEVEL";
