import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import { countries } from "./countries";
import { Autocomplete } from "@material-ui/lab";

/**
 * Autocomplete component of countries with flags
 * @component
 */
const CountriesInputV2 = ({ inputLabel, setValue }) => {
  const countryOptions = countries;
  const [inputValue, setInputValue] = useState("");

  const renderOption = (option) => (
    <React.Fragment>
      <img
        src={option.image}
        alt={option.code}
        style={{ height: "25px", paddingRight: "2px" }}
      />
      {option.name}
    </React.Fragment>
  );
  //set value for save on data base
  const handleChange = (_event, newValue) => {
    setValue(newValue ? newValue.name : "");
  };
  //set value for show on input
  const handleInputChange = (_event, value) => {
    setInputValue(value);
  };

  return (
    <Autocomplete
      fullWidth
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onChange={handleChange}
      options={countryOptions}
      getOptionLabel={(option) => option.emoji + " " + option.name}
      renderOption={renderOption}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={inputLabel}
            variant="standard"
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password", // disable autocomplete and autofill
            }}
          />
        );
      }}
    />
  );
};

export default CountriesInputV2;
