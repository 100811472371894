import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Card,
  CardContent,
  Button,
  TextField,
  FormControl,
  Checkbox,
} from "@material-ui/core";

import { Alert, Loader } from "components";
import NoSessionNavigation from "../../components/NoSessionNavigation/NoSessionNavigation";
import { loginUser, reset } from "../../redux/actions";
import { required, validEmail } from "../../utils/validations";
import styles from "./login.module.scss";
import { Footer } from "layout-components";

export const useUser = () => {
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const handleInputChange = (inputElementEvent) => {
    setUser({
      ...user,
      [inputElementEvent.target.name]: inputElementEvent.target.value,
    });
  };
  return { user, handleInputChange };
};

const Login = ({ history }) => {
  const { i18n, t } = useTranslation("login");
  const currentLanguage = i18n.language;
  const dispatch = useDispatch();
  const { user, handleInputChange } = useUser();

  const [remindMeSession, setRemindMeSession] = useState(true);

  const {
    loading: loginLoading,
    error: loginError,
    loginSuccess,
  } = useSelector((store) => store.loggedUser);
  const { eventInfo } = useSelector((store) => store.eventInfo);
  const disableLoginBoolean = !required(user.email) || !validEmail(user.email);

  useEffect(() => {
    dispatch(reset());
  }, [dispatch]);

  useEffect(() => {
    if (loginSuccess) {
      history.push("/dashboard");
    }
  }, [loginSuccess, history]);

  const loginAction = () => {
    const payload = {
      email: user.email,
    };

    dispatch(loginUser(payload, remindMeSession));
  };

  const goChooseService = () => {
    history.push({ pathname: "/register" });
  };

  return (
    <Grid
      container
      style={{
        backgroundImage: `url(${eventInfo.assets.loginBanner})`,
      }}
      className={styles["page-wrapper"]}
    >
      <div className={styles["navigation-buttons"]}>
        <NoSessionNavigation backButton={false} />
      </div>
      <Grid item className={styles["image-container"]}>
        <img
          alt="Login left content"
          src={eventInfo.assets.mainLogo}
          className={styles["cover-image"]}
        />
      </Grid>

      <Grid item className={styles["form-container"]}>
        <h1 className={styles["form-title"]}>
          {eventInfo.title[currentLanguage] || ""}
        </h1>
        <p className={styles["form-subtitle"]}>
          {eventInfo.name[currentLanguage] || ""}
        </p>

        <Card className={styles["form-card"]}>
          <CardContent>
            <p className={styles["form-card-title"]}>{t("login.title")}</p>

            <FormControl className="w-100">
              <TextField
                type="email"
                value={user.email}
                name="email"
                label={t("login.email")}
                placeholder={t("login.emailPlaceholder")}
                fullWidth
                onChange={handleInputChange}
                error={
                  user.email.length > 0 &&
                  (!required(user.email) || !validEmail(user.email))
                }
                helperText={
                  user.email.length > 0
                    ? !required(user.email) || !validEmail(user.email)
                      ? t("login.emailNotValid")
                      : null
                    : null
                }
              />
            </FormControl>
            <Checkbox
              color="primary"
              className="pl-0"
              checked={remindMeSession}
              onChange={() => setRemindMeSession(!remindMeSession)}
            />
            <small className="text-dark">{t("login.keepLogged")}</small>

            <div className={styles["form-card-loader-container"]}>
              {loginLoading ? (
                <Loader />
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  className="w-100 font-weight-bold custom-primary-btn"
                  disabled={disableLoginBoolean}
                  onClick={loginAction}
                >
                  {t("login.logginButton")}
                </Button>
              )}
            </div>
            <div className="mt-2">
              {loginError ? (
                <Alert severity="error" message={loginError} />
              ) : null}
            </div>

            {/* LINE SEPARATOR */}
            <div
              className="mt-2 mb-4"
              style={{ height: 1, backgroundColor: "#cdd4d3" }}
            />

            <p className={styles["form-card-register-here-label"]}>
              {t("login.registerHere")}
            </p>
            <Button
              variant="contained"
              size="large"
              className={`${styles["form-card-register-btn"]} custom-primary-btn`}
              onClick={goChooseService}
            >
              {t("login.registerButton")}
            </Button>

            <div className={styles["form-card-rights-label"]}>
              <Footer />
            </div>
          </CardContent>
        </Card>

        <div className={styles["outer-rights-label"]}>
          <Footer />
        </div>
      </Grid>
    </Grid>
  );
};

export default Login;
